 @font-face {
     font-family: 'Material Icons';
     font-style: normal;
     font-weight: 400;
     src: url('../../defaultfont/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
 }

 .material-icons {
     font-family: 'Material Icons';
     font-weight: normal;
     font-style: normal;
     font-size: 24px;
     line-height: 1;
     letter-spacing: normal;
     text-transform: none;
     display: inline-block;
     white-space: nowrap;
     word-wrap: normal;
     direction: ltr;
     -webkit-font-feature-settings: 'liga';
     -webkit-font-smoothing: antialiased;
 }

 @font-face {
     font-family: 'Material-Icons';
     src: url('custom-material-Icons.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 }

 [class^="material-icons-"]:before,
 [class*=" material-icons-"]:before {
     font-family: 'Material-Icons' !important;
     font-style: normal;
     font-weight: normal !important;
     font-variant: normal;
     text-transform: none;
     line-height: 0 !important;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
 }

 [class^="svg-icons-"]:before,
 [class*=" svg-icons-"]:before {
     font-family: custom-icons !important;
     font-style: normal;
     font-weight: normal !important;
     font-variant: normal;
     text-transform: none;
     line-height: 0 !important;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
 }

 [class^="svg-icons-"],
 [class^="material-icons-"],
 [class*="svg-icons-"],
 [class*="material-icons-"] {
     line-height: 1 !important;
 }

 .material-icons-k:before {
     content: "\e900";
 }

 .material-icons-360:before {
     content: "\e901";
 }

 .material-icons-accessibility-new:before {
     content: "\e902";
 }

 .material-icons-accessible-forward:before {
     content: "\e903";
 }

 .material-icons-add-comment:before {
     content: "\e904";
 }

 .material-icons-add-photo-alternate:before {
     content: "\e905";
 }

 .material-icons-add-to-home-screen:before {
     content: "\e906";
 }

 .material-icons-all-inbox:before {
     content: "\e907";
 }

 .material-icons-alternate-email:before {
     content: "\e908";
 }

 .material-icons-arrow-back-ios:before {
     content: "\e909";
 }

 .material-icons-arrow-forward-ios:before {
     content: "\e90a";
 }

 .material-icons-arrow-left:before {
     content: "\e90b";
 }

 .material-icons-arrow-right:before {
     content: "\e90c";
 }

 .material-icons-arrow-right-alt:before {
     content: "\e90d";
 }

 .material-icons-atm:before {
     content: "\e90e";
 }

 .material-icons-ballot:before {
     content: "\e90f";
 }

 .material-icons-bar-chart:before {
     content: "\e910";
 }

 .material-icons-battery-20 .path1:before {
     content: "\e911";
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-20 .path2:before {
     content: "\e912";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-30 .path1:before {
     content: "\e913";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-30 .path2:before {
     content: "\e914";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-50 .path1:before {
     content: "\e915";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-50 .path2:before {
     content: "\e916";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-80 .path1:before {
     content: "\e917";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-80 .path2:before {
     content: "\e918";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-90 .path1:before {
     content: "\e919";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-90 .path2:before {
     content: "\e91a";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-20 .path1:before {
     content: "\e91b";
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-20 .path2:before {
     content: "\e91c";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-30 .path1:before {
     content: "\e91d";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-30 .path2:before {
     content: "\e91e";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-50 .path1:before {
     content: "\e91f";
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-50 .path2:before {
     content: "\e920";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-60 .path1:before {
     content: "\e921";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-60 .path2:before {
     content: "\e922";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-80 .path1:before {
     content: "\e923";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-80 .path2:before {
     content: "\e924";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-90 .path1:before {
     content: "\e925";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-90 .path2:before {
     content: "\e926";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-bookmarks:before {
     content: "\e927";
 }

 .material-icons-calendar-today:before {
     content: "\e928";
 }

 .material-icons-calendar-view-day:before {
     content: "\e929";
 }

 .material-icons-cancel-presentation:before {
     content: "\e92a";
 }

 .material-icons-cast-for-education:before {
     content: "\e92b";
 }

 .material-icons-category:before {
     content: "\e92c";
 }

 .material-icons-check-circle-outline:before {
     content: "\e92f";
 }

 .material-icons-commute:before {
     content: "\e930";
 }

 .material-icons-compass-calibration:before {
     content: "\e931";
 }

 .material-icons-contact-support:before {
     content: "\e932";
 }

 .material-icons-control-camera:before {
     content: "\e933";
 }

 .material-icons-delete-outline:before {
     content: "\e934";
 }

 .material-icons-departure-board:before {
     content: "\e935";
 }

 .material-icons-desktop-access-disabled:before {
     content: "\e936";
 }

 .material-icons-device-unknown:before {
     content: "\e937";
 }

 .material-icons-domain-disabled:before {
     content: "\e938";
 }

 .material-icons-done-outline:before {
     content: "\e939";
 }

 .material-icons-duo:before {
     content: "\e93a";
 }

 .material-icons-edit-attributes:before {
     content: "\e93b";
 }

 .material-icons-explore-off:before {
     content: "\e93c";
 }

 .material-icons-fastfood:before {
     content: "\e93d";
 }

 .material-icons-file-copy:before {
     content: "\e93e";
 }

 .material-icons-format-list-numbered-rtl:before {
     content: "\e93f";
 }

 .material-icons-horizontal-split:before {
     content: "\e940";
 }

 .material-icons-how-to-reg:before {
     content: "\e941";
 }

 .material-icons-how-to-vote:before {
     content: "\e942";
 }

 .material-icons-image-search:before {
     content: "\e943";
 }

 .material-icons-insert-chart-outlined:before {
     content: "\e944";
 }

 .material-icons-label-important:before {
     content: "\e945";
 }

 .material-icons-label-off:before {
     content: "\e946";
 }

 .material-icons-link-off:before {
     content: "\e947";
 }

 .material-icons-list-alt:before {
     content: "\e948";
 }

 .material-icons-maximize:before {
     content: "\e949";
 }

 .material-icons-meeting-room:before {
     content: "\e94a";
 }

 .material-icons-minimize:before {
     content: "\e94b";
 }

 .material-icons-missed-video-call:before {
     content: "\e94c";
 }

 .material-icons-mobile-friendly:before {
     content: "\e94d";
 }

 .material-icons-mobile-off:before {
     content: "\e94e";
 }

 .material-icons-mobile-screen-share:before {
     content: "\e94f";
 }

 .material-icons-money:before {
     content: "\e950";
 }

 .material-icons-music-off:before {
     content: "\e951";
 }

 .material-icons-no-meeting-room:before {
     content: "\e952";
 }

 .material-icons-not-listed-location:before {
     content: "\e953";
 }

 .material-icons-notes:before {
     content: "\e954";
 }

 .material-icons-notification-important:before {
     content: "\e955";
 }

 .material-icons-offline-bolt:before {
     content: "\e956";
 }

 .material-icons-outlined-flag:before {
     content: "\e957";
 }

 .material-icons-pause-presentation:before {
     content: "\e958";
 }

 .material-icons-person-add-disabled:before {
     content: "\e959";
 }

 .material-icons-phone-callback:before {
     content: "\e95a";
 }

 .material-icons-play-circle-filled-white:before {
     content: "\e95b";
     color: #fff;
 }

 .material-icons-power-off:before {
     content: "\e95c";
 }

 .material-icons-print-disabled:before {
     content: "\e95d";
 }

 .material-icons-report-off:before {
     content: "\e95e";
 }

 .material-icons-restore-from-trash:before {
     content: "\e95f";
 }

 .material-icons-save-alt:before {
     content: "\e960";
 }

 .material-icons-scatter-plot:before {
     content: "\e961";
 }

 .material-icons-score:before {
     content: "\e962";
 }

 .material-icons-sentiment-satisfied-alt:before {
     content: "\e963";
 }

 .material-icons-shutter-speed:before {
     content: "\e964";
 }

 .material-icons-signal-cellular-0-bar:before {
     content: "\e965";
 }

 .material-icons-signal-cellular-1-bar .path1:before {
     content: "\e966";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-1-bar .path2:before {
     content: "\e967";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-cellular-2-bar .path1:before {
     content: "\e968";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-2-bar .path2:before {
     content: "\e969";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-cellular-3-bar .path1:before {
     content: "\e96a";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-3-bar .path2:before {
     content: "\e96b";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-cellular-alt:before {
     content: "\e96c";
 }

 .material-icons-signal-cellular-connected-no-internet-0-bar .path1:before {
     content: "\e96d";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-connected-no-internet-0-bar .path2:before {
     content: "\e96e";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-cellular-connected-no-internet-1-bar .path1:before {
     content: "\e96f";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-connected-no-internet-1-bar .path2:before {
     content: "\e970";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-cellular-connected-no-internet-2-bar .path1:before {
     content: "\e971";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-connected-no-internet-2-bar .path2:before {
     content: "\e972";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-cellular-connected-no-internet-3-bar .path1:before {
     content: "\e973";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-connected-no-internet-3-bar .path2:before {
     content: "\e974";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-0-bar:before {
     content: "\e975";
 }

 .material-icons-signal-wifi-1-bar .path1:before {
     content: "\e976";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-wifi-1-bar .path2:before {
     content: "\e977";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-1-bar-lock .path1:before {
     content: "\e978";
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-1-bar-lock .path2:before {
     content: "\e979";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-wifi-1-bar-lock .path3:before {
     content: "\e97a";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-2-bar .path1:before {
     content: "\e97b";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-wifi-2-bar .path2:before {
     content: "\e97c";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-2-bar-lock .path1:before {
     content: "\e97d";
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-2-bar-lock .path2:before {
     content: "\e97e";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-wifi-2-bar-lock .path3:before {
     content: "\e97f";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-3-bar .path1:before {
     content: "\e980";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-wifi-3-bar .path2:before {
     content: "\e981";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-signal-wifi-3-bar-lock .path1:before {
     content: "\e982";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-wifi-3-bar-lock .path2:before {
     content: "\e983";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-star-rate:before {
     content: "\e984";
 }

 .material-icons-supervised-user-circle:before {
     content: "\e985";
 }

 .material-icons-swap-horizontal-circle:before {
     content: "\e986";
 }

 .material-icons-table-chart:before {
     content: "\e987";
 }

 .material-icons-text-rotate-up:before {
     content: "\e988";
 }

 .material-icons-text-rotate-vertical:before {
     content: "\e989";
 }

 .material-icons-text-rotation-down:before {
     content: "\e98a";
 }

 .material-icons-text-rotation-none:before {
     content: "\e98b";
 }

 .material-icons-thumb-down-alt:before {
     content: "\e98c";
 }

 .material-icons-thumb-up-alt:before {
     content: "\e98d";
 }

 .material-icons-toggle-off:before {
     content: "\e98e";
 }

 .material-icons-transit-enterexit:before {
     content: "\e98f";
 }

 .material-icons-trip-origin:before {
     content: "\e990";
 }

 .material-icons-tv-off:before {
     content: "\e991";
 }

 .material-icons-unsubscribe:before {
     content: "\e992";
 }

 .material-icons-vertical-split:before {
     content: "\e993";
 }

 .material-icons-voice-over-off:before {
     content: "\e994";
 }

 .material-icons-waves:before {
     content: "\e995";
 }

 .material-icons-where-to-vote:before {
     content: "\e996";
 }

 .material-icons-wifi-off:before {
     content: "\e997";
 }

 .material-icons-work-off:before {
     content: "\e998";
 }

 .material-icons-work-outline:before {
     content: "\e999";
 }

 .material-icons-check-box:before {
     content: "\e99a";
 }

 .material-icons-check-box-outline-blank:before {
     content: "\e99b";
 }

 .material-icons-indeterminate-check-box:before {
     content: "\e99c";
 }

 .material-icons-radio-button-checked:before {
     content: "\e99d";
 }

 .material-icons-radio-button-unchecked:before {
     content: "\e99e";
 }

 .material-icons-star:before {
     content: "\e99f";
 }

 .material-icons-star-border:before {
     content: "\e9a0";
 }

 .material-icons-star-half:before {
     content: "\e9a1";
 }

 .material-icons-cake:before {
     content: "\e9a2";
 }

 .material-icons-domain:before {
     content: "\e9a3";
 }

 .material-icons-group:before {
     content: "\e9a4";
 }

 .material-icons-group-add:before {
     content: "\e9a5";
 }

 .material-icons-location-city:before {
     content: "\e9a6";
 }

 .material-icons-mood:before {
     content: "\e9a7";
 }

 .material-icons-mood-bad:before {
     content: "\e9a8";
 }

 .material-icons-notifications:before {
     content: "\e9a9";
 }

 .material-icons-notifications-active:before {
     content: "\e9aa";
 }

 .material-icons-notifications-none:before {
     content: "\e9ab";
 }

 .material-icons-notifications-off:before {
     content: "\e9ac";
 }

 .material-icons-notifications-paused:before {
     content: "\e9ad";
 }

 .material-icons-pages:before {
     content: "\e9ae";
 }

 .material-icons-party-mode:before {
     content: "\e9af";
 }

 .material-icons-people:before {
     content: "\e9b0";
 }

 .material-icons-people-outline:before {
     content: "\e9b1";
 }

 .material-icons-person:before {
     content: "\e9b2";
 }

 .material-icons-person-add:before {
     content: "\e9b3";
 }

 .material-icons-person-outline:before {
     content: "\e9b4";
 }

 .material-icons-plus-one:before {
     content: "\e9b5";
 }

 .material-icons-poll:before {
     content: "\e9b6";
 }

 .material-icons-public:before {
     content: "\e9b7";
 }

 .material-icons-school:before {
     content: "\e9b8";
 }

 .material-icons-sentiment-dissatisfied:before {
     content: "\e9b9";
 }

 .material-icons-sentiment-neutral:before {
     content: "\e9ba";
 }

 .material-icons-sentiment-satisfied:before {
     content: "\e9bb";
 }

 .material-icons-sentiment-very-dissatisfied:before {
     content: "\e9bc";
 }

 .material-icons-sentiment-very-satisfied:before {
     content: "\e9bd";
 }

 .material-icons-share:before {
     content: "\e9be";
 }

 .material-icons-whatshot:before {
     content: "\e9bf";
 }

 .material-icons-ac-unit:before {
     content: "\e9c0";
 }

 .material-icons-airport-shuttle:before {
     content: "\e9c1";
 }

 .material-icons-all-inclusive:before {
     content: "\e9c2";
 }

 .material-icons-beach-access:before {
     content: "\e9c3";
 }

 .material-icons-business-center:before {
     content: "\e9c4";
 }

 .material-icons-casino:before {
     content: "\e9c5";
 }

 .material-icons-child-care:before {
     content: "\e9c6";
 }

 .material-icons-child-friendly:before {
     content: "\e9c7";
 }

 .material-icons-fitness-center:before {
     content: "\e9c8";
 }

 .material-icons-free-breakfast:before {
     content: "\e9c9";
 }

 .material-icons-golf-course:before {
     content: "\e9ca";
 }

 .material-icons-hot-tub:before {
     content: "\e9cb";
 }

 .material-icons-kitchen:before {
     content: "\e9cc";
 }

 .material-icons-pool:before {
     content: "\e9cd";
 }

 .material-icons-room-service:before {
     content: "\e9ce";
 }

 .material-icons-rv-hookup:before {
     content: "\e9cf";
 }

 .material-icons-smoke-free:before {
     content: "\e9d0";
 }

 .material-icons-smoking-rooms:before {
     content: "\e9d1";
 }

 .material-icons-adb:before {
     content: "\e9d4";
 }

 .material-icons-airline-seat-flat:before {
     content: "\e9d5";
 }

 .material-icons-airline-seat-flat-angled:before {
     content: "\e9d6";
 }

 .material-icons-airline-seat-individual-suite:before {
     content: "\e9d7";
 }

 .material-icons-airline-seat-legroom-extra:before {
     content: "\e9d8";
 }

 .material-icons-airline-seat-legroom-normal:before {
     content: "\e9d9";
 }

 .material-icons-airline-seat-legroom-reduced:before {
     content: "\e9da";
 }

 .material-icons-airline-seat-recline-extra:before {
     content: "\e9db";
 }

 .material-icons-airline-seat-recline-normal:before {
     content: "\e9dc";
 }

 .material-icons-bluetooth-audio:before {
     content: "\e9dd";
 }

 .material-icons-confirmation-number:before {
     content: "\e9de";
 }

 .material-icons-disc-full:before {
     content: "\e9df";
 }

 .material-icons-do-not-disturb:before {
     content: "\e9e0";
 }

 .material-icons-do-not-disturb-alt:before {
     content: "\e9e1";
 }

 .material-icons-do-not-disturb-off:before {
     content: "\e9e2";
 }

 .material-icons-do-not-disturb-on:before {
     content: "\e9e3";
 }

 .material-icons-drive-eta:before {
     content: "\e9e4";
 }

 .material-icons-enhanced-encryption:before {
     content: "\e9e5";
 }

 .material-icons-event-available:before {
     content: "\e9e6";
 }

 .material-icons-event-busy:before {
     content: "\e9e7";
 }

 .material-icons-event-note:before {
     content: "\e9e8";
 }

 .material-icons-folder-special:before {
     content: "\e9e9";
 }

 .material-icons-live-tv:before {
     content: "\e9ea";
 }

 .material-icons-mms:before {
     content: "\e9eb";
 }

 .material-icons-more:before {
     content: "\e9ec";
 }

 .material-icons-network-check:before {
     content: "\e9ed";
 }

 .material-icons-network-locked:before {
     content: "\e9ee";
 }

 .material-icons-no-encryption:before {
     content: "\e9ef";
 }

 .material-icons-ondemand-video:before {
     content: "\e9f0";
 }

 .material-icons-personal-video:before {
     content: "\e9f1";
 }

 .material-icons-phone-bluetooth-speaker:before {
     content: "\e9f2";
 }

 .material-icons-phone-forwarded:before {
     content: "\e9f3";
 }

 .material-icons-phone-in-talk:before {
     content: "\e9f4";
 }

 .material-icons-phone-locked:before {
     content: "\e9f5";
 }

 .material-icons-phone-missed:before {
     content: "\e9f6";
 }

 .material-icons-phone-paused:before {
     content: "\e9f7";
 }

 .material-icons-power:before {
     content: "\e9f8";
 }

 .material-icons-priority-high:before {
     content: "\e9f9";
 }

 .material-icons-rv-hookup1:before {
     content: "\e9fa";
 }

 .material-icons-sd-card:before {
     content: "\e9fb";
 }

 .material-icons-sim-card-alert:before {
     content: "\e9fc";
 }

 .material-icons-sms:before {
     content: "\e9fd";
 }

 .material-icons-sms-failed:before {
     content: "\e9fe";
 }

 .material-icons-sync:before {
     content: "\e9ff";
 }

 .material-icons-sync-disabled:before {
     content: "\ea00";
 }

 .material-icons-sync-problem:before {
     content: "\ea01";
 }

 .material-icons-system-update:before {
     content: "\ea02";
 }

 .material-icons-tap-and-play:before {
     content: "\ea03";
 }

 .material-icons-time-to-leave:before {
     content: "\ea04";
 }

 .material-icons-vibration:before {
     content: "\ea05";
 }

 .material-icons-voice-chat:before {
     content: "\ea06";
 }

 .material-icons-vpn-lock:before {
     content: "\ea07";
 }

 .material-icons-wc:before {
     content: "\ea08";
 }

 .material-icons-wifi:before {
     content: "\ea09";
 }

 .material-icons-apps:before {
     content: "\ea0a";
 }

 .material-icons-arrow-back:before {
     content: "\ea0b";
 }

 .material-icons-arrow-downward:before {
     content: "\ea0c";
 }

 .material-icons-arrow-drop-down:before {
     content: "\ea0d";
 }

 .material-icons-arrow-drop-down-circle:before {
     content: "\ea0e";
 }

 .material-icons-arrow-drop-up:before {
     content: "\ea0f";
 }

 .material-icons-arrow-forward:before {
     content: "\ea10";
 }

 .material-icons-arrow-upward:before {
     content: "\ea11";
 }

 .material-icons-cancel:before {
     content: "\ea12";
 }

 .material-icons-check:before {
     content: "\ea13";
 }

 .material-icons-chevron-left:before {
     content: "\ea14";
 }

 .material-icons-chevron-right:before {
     content: "\ea15";
 }

 .material-icons-close:before {
     content: "\ea16";
 }

 .material-icons-expand-less:before {
     content: "\ea17";
 }

 .material-icons-expand-more:before {
     content: "\ea18";
 }

 .material-icons-first-page:before {
     content: "\ea19";
 }

 .material-icons-fullscreen:before {
     content: "\ea1a";
 }

 .material-icons-fullscreen-exit:before {
     content: "\ea1b";
 }

 .material-icons-last-page:before {
     content: "\ea1c";
 }

 .material-icons-menu:before {
     content: "\ea1d";
 }

 .material-icons-more-horiz:before {
     content: "\ea1e";
 }

 .material-icons-more-vert:before {
     content: "\ea1f";
 }

 .material-icons-refresh:before {
     content: "\ea20";
 }

 .material-icons-subdirectory-arrow-left:before {
     content: "\ea21";
 }

 .material-icons-subdirectory-arrow-right:before {
     content: "\ea22";
 }

 .material-icons-unfold-less:before {
     content: "\ea23";
 }

 .material-icons-unfold-more:before {
     content: "\ea24";
 }

 .material-icons-add-location:before {
     content: "\ea25";
 }

 .material-icons-beenhere:before {
     content: "\ea26";
 }

 .material-icons-directions:before {
     content: "\ea27";
 }

 .material-icons-directions-bike:before {
     content: "\ea28";
 }

 .material-icons-directions-boat:before {
     content: "\ea29";
 }

 .material-icons-directions-bus:before {
     content: "\ea2a";
 }

 .material-icons-directions-car:before {
     content: "\ea2b";
 }

 .material-icons-directions-railway:before {
     content: "\ea2c";
 }

 .material-icons-directions-run:before {
     content: "\ea2d";
 }

 .material-icons-directions-subway:before {
     content: "\ea2e";
 }

 .material-icons-directions-transit:before {
     content: "\ea2f";
 }

 .material-icons-directions-walk:before {
     content: "\ea30";
 }

 .material-icons-edit-location:before {
     content: "\ea31";
 }

 .material-icons-ev-station:before {
     content: "\ea32";
 }

 .material-icons-flight:before {
     content: "\ea33";
 }

 .material-icons-hotel:before {
     content: "\ea34";
 }

 .material-icons-layers:before {
     content: "\ea35";
 }

 .material-icons-layers-clear:before {
     content: "\ea36";
 }

 .material-icons-local-activity:before {
     content: "\ea37";
 }

 .material-icons-local-airport:before {
     content: "\ea38";
 }

 .material-icons-local-atm:before {
     content: "\ea39";
 }

 .material-icons-local-bar:before {
     content: "\ea3a";
 }

 .material-icons-local-cafe:before {
     content: "\ea3b";
 }

 .material-icons-local-car-wash:before {
     content: "\ea3c";
 }

 .material-icons-local-convenience-store:before {
     content: "\ea3d";
 }

 .material-icons-local-dining:before {
     content: "\ea3e";
 }

 .material-icons-local-drink:before {
     content: "\ea3f";
 }

 .material-icons-local-florist:before {
     content: "\ea40";
 }

 .material-icons-local-gas-station:before {
     content: "\ea41";
 }

 .material-icons-local-grocery-store:before {
     content: "\ea42";
 }

 .material-icons-local-hospital:before {
     content: "\ea43";
 }

 .material-icons-local-hotel:before {
     content: "\ea44";
 }

 .material-icons-local-laundry-service:before {
     content: "\ea45";
 }

 .material-icons-local-library:before {
     content: "\ea46";
 }

 .material-icons-local-mall:before {
     content: "\ea47";
 }

 .material-icons-local-movies:before {
     content: "\ea48";
 }

 .material-icons-local-offer:before {
     content: "\ea49";
 }

 .material-icons-local-parking:before {
     content: "\ea4a";
 }

 .material-icons-local-pharmacy:before {
     content: "\ea4b";
 }

 .material-icons-local-phone:before {
     content: "\ea4c";
 }

 .material-icons-local-pizza:before {
     content: "\ea4d";
 }

 .material-icons-local-play:before {
     content: "\ea4e";
 }

 .material-icons-local-post-office:before {
     content: "\ea4f";
 }

 .material-icons-local-printshop:before {
     content: "\ea50";
 }

 .material-icons-local-see:before {
     content: "\ea51";
 }

 .material-icons-local-shipping:before {
     content: "\ea52";
 }

 .material-icons-local-taxi:before {
     content: "\ea53";
 }

 .material-icons-map:before {
     content: "\ea54";
 }

 .material-icons-my-location:before {
     content: "\ea55";
 }

 .material-icons-navigation:before {
     content: "\ea56";
 }

 .material-icons-near-me:before {
     content: "\ea57";
 }

 .material-icons-person-pin:before {
     content: "\ea58";
 }

 .material-icons-person-pin-circle:before {
     content: "\ea59";
 }

 .material-icons-pin-drop:before {
     content: "\ea5a";
 }

 .material-icons-place:before {
     content: "\ea5b";
 }

 .material-icons-rate-review:before {
     content: "\ea5c";
 }

 .material-icons-restaurant:before {
     content: "\ea5d";
 }

 .material-icons-restaurant-menu:before {
     content: "\ea5e";
 }

 .material-icons-satellite:before {
     content: "\ea5f";
 }

 .material-icons-store-mall-directory:before {
     content: "\ea60";
 }

 .material-icons-streetview:before {
     content: "\ea61";
 }

 .material-icons-subway:before {
     content: "\ea62";
 }

 .material-icons-terrain:before {
     content: "\ea63";
 }

 .material-icons-traffic:before {
     content: "\ea64";
 }

 .material-icons-train:before {
     content: "\ea65";
 }

 .material-icons-tram:before {
     content: "\ea66";
 }

 .material-icons-transfer-within-a-station:before {
     content: "\ea67";
 }

 .material-icons-zoom-out-map:before {
     content: "\ea68";
 }

 .material-icons-add-a-photo:before {
     content: "\ea69";
 }

 .material-icons-add-to-photos:before {
     content: "\ea6a";
 }

 .material-icons-adjust:before {
     content: "\ea6b";
 }

 .material-icons-assistant:before {
     content: "\ea6c";
 }

 .material-icons-assistant-photo:before {
     content: "\ea6d";
 }

 .material-icons-audiotrack:before {
     content: "\ea6e";
 }

 .material-icons-blur-circular:before {
     content: "\ea6f";
 }

 .material-icons-blur-linear:before {
     content: "\ea70";
 }

 .material-icons-blur-off:before {
     content: "\ea71";
 }

 .material-icons-blur-on:before {
     content: "\ea72";
 }

 .material-icons-brightness-1:before {
     content: "\ea73";
 }

 .material-icons-brightness-2:before {
     content: "\ea74";
 }

 .material-icons-brightness-3:before {
     content: "\ea75";
 }

 .material-icons-brightness-4:before {
     content: "\ea76";
 }

 .material-icons-brightness-5:before {
     content: "\ea77";
 }

 .material-icons-brightness-6:before {
     content: "\ea78";
 }

 .material-icons-brightness-7:before {
     content: "\ea79";
 }

 .material-icons-broken-image:before {
     content: "\ea7a";
 }

 .material-icons-brush:before {
     content: "\ea7b";
 }

 .material-icons-burst-mode:before {
     content: "\ea7c";
 }

 .material-icons-camera:before {
     content: "\ea7d";
 }

 .material-icons-camera-alt:before {
     content: "\ea7e";
 }

 .material-icons-camera-front:before {
     content: "\ea7f";
 }

 .material-icons-camera-rear:before {
     content: "\ea80";
 }

 .material-icons-camera-roll:before {
     content: "\ea81";
 }

 .material-icons-center-focus-strong:before {
     content: "\ea82";
 }

 .material-icons-center-focus-weak:before {
     content: "\ea83";
 }

 .material-icons-collections:before {
     content: "\ea84";
 }

 .material-icons-collections-bookmark:before {
     content: "\ea85";
 }

 .material-icons-color-lens:before {
     content: "\ea86";
 }

 .material-icons-colorize:before {
     content: "\ea87";
 }

 .material-icons-compare:before {
     content: "\ea88";
 }

 .material-icons-control-point:before {
     content: "\ea89";
 }

 .material-icons-control-point-duplicate:before {
     content: "\ea8a";
 }

 .material-icons-crop-3-2:before {
     content: "\ea8b";
 }

 .material-icons-crop-5-4:before {
     content: "\ea8c";
 }

 .material-icons-crop-7-5:before {
     content: "\ea8d";
 }

 .material-icons-crop-16-9:before {
     content: "\ea8e";
 }

 .material-icons-crop:before {
     content: "\ea8f";
 }

 .material-icons-crop-din:before {
     content: "\ea90";
 }

 .material-icons-crop-free:before {
     content: "\ea91";
 }

 .material-icons-crop-landscape:before {
     content: "\ea92";
 }

 .material-icons-crop-original:before {
     content: "\ea93";
 }

 .material-icons-crop-portrait:before {
     content: "\ea94";
 }

 .material-icons-crop-rotate:before {
     content: "\ea95";
 }

 .material-icons-crop-square:before {
     content: "\ea96";
 }

 .material-icons-dehaze:before {
     content: "\ea97";
 }

 .material-icons-details:before {
     content: "\ea98";
 }

 .material-icons-edit:before {
     content: "\ea99";
 }

 .material-icons-exposure:before {
     content: "\ea9a";
 }

 .material-icons-exposure-neg-1:before {
     content: "\ea9b";
 }

 .material-icons-exposure-neg-2:before {
     content: "\ea9c";
 }

 .material-icons-exposure-plus-1:before {
     content: "\ea9d";
 }

 .material-icons-exposure-plus-2:before {
     content: "\ea9e";
 }

 .material-icons-exposure-zero:before {
     content: "\ea9f";
 }

 .material-icons-filter-1:before {
     content: "\eaa0";
 }

 .material-icons-filter-2:before {
     content: "\eaa1";
 }

 .material-icons-filter-3:before {
     content: "\eaa2";
 }

 .material-icons-filter-4:before {
     content: "\eaa3";
 }

 .material-icons-filter-5:before {
     content: "\eaa4";
 }

 .material-icons-filter-6:before {
     content: "\eaa5";
 }

 .material-icons-filter-7:before {
     content: "\eaa6";
 }

 .material-icons-filter-8:before {
     content: "\eaa7";
 }

 .material-icons-filter-9:before {
     content: "\eaa8";
 }

 .material-icons-filter-9-plus:before {
     content: "\eaa9";
 }

 .material-icons-filter:before {
     content: "\eaaa";
 }

 .material-icons-filter-b-and-w:before {
     content: "\eaab";
 }

 .material-icons-filter-center-focus:before {
     content: "\eaac";
 }

 .material-icons-filter-drama:before {
     content: "\eaad";
 }

 .material-icons-filter-frames:before {
     content: "\eaae";
 }

 .material-icons-filter-hdr:before {
     content: "\eaaf";
 }

 .material-icons-filter-none:before {
     content: "\eab0";
 }

 .material-icons-filter-tilt-shift:before {
     content: "\eab1";
 }

 .material-icons-filter-vintage:before {
     content: "\eab2";
 }

 .material-icons-flare:before {
     content: "\eab3";
 }

 .material-icons-flash-auto:before {
     content: "\eab4";
 }

 .material-icons-flash-off:before {
     content: "\eab5";
 }

 .material-icons-flash-on:before {
     content: "\eab6";
 }

 .material-icons-flip:before {
     content: "\eab7";
 }

 .material-icons-gradient:before {
     content: "\eab8";
 }

 .material-icons-grain:before {
     content: "\eab9";
 }

 .material-icons-grid-off:before {
     content: "\eaba";
 }

 .material-icons-grid-on:before {
     content: "\eabb";
 }

 .material-icons-hdr-off:before {
     content: "\eabc";
 }

 .material-icons-hdr-on:before {
     content: "\eabd";
 }

 .material-icons-hdr-strong:before {
     content: "\eabe";
 }

 .material-icons-hdr-weak:before {
     content: "\eabf";
 }

 .material-icons-healing:before {
     content: "\eac0";
 }

 .material-icons-image:before {
     content: "\eac1";
 }

 .material-icons-image-aspect-ratio:before {
     content: "\eac2";
 }

 .material-icons-iso:before {
     content: "\eac3";
 }

 .material-icons-landscape:before {
     content: "\eac4";
 }

 .material-icons-leak-add:before {
     content: "\eac5";
 }

 .material-icons-leak-remove:before {
     content: "\eac6";
 }

 .material-icons-lens:before {
     content: "\eac7";
 }

 .material-icons-linked-camera:before {
     content: "\eac8";
 }

 .material-icons-looks-3:before {
     content: "\eac9";
 }

 .material-icons-looks-4:before {
     content: "\eaca";
 }

 .material-icons-looks-5:before {
     content: "\eacb";
 }

 .material-icons-looks-6:before {
     content: "\eacc";
 }

 .material-icons-looks:before {
     content: "\eacd";
 }

 .material-icons-looks-one:before {
     content: "\eace";
 }

 .material-icons-looks-two:before {
     content: "\eacf";
 }

 .material-icons-loupe:before {
     content: "\ead0";
 }

 .material-icons-monochrome-photos:before {
     content: "\ead1";
 }

 .material-icons-movie-creation:before {
     content: "\ead2";
 }

 .material-icons-movie-filter:before {
     content: "\ead3";
 }

 .material-icons-music-note:before {
     content: "\ead4";
 }

 .material-icons-nature:before {
     content: "\ead5";
 }

 .material-icons-nature-people:before {
     content: "\ead6";
 }

 .material-icons-navigate-before:before {
     content: "\ead7";
 }

 .material-icons-navigate-next:before {
     content: "\ead8";
 }

 .material-icons-palette:before {
     content: "\ead9";
 }

 .material-icons-panorama:before {
     content: "\eada";
 }

 .material-icons-panorama-fish-eye:before {
     content: "\eadb";
 }

 .material-icons-panorama-horizontal:before {
     content: "\eadc";
 }

 .material-icons-panorama-vertical:before {
     content: "\eadd";
 }

 .material-icons-panorama-wide-angle:before {
     content: "\eade";
 }

 .material-icons-photo:before {
     content: "\eadf";
 }

 .material-icons-photo-album:before {
     content: "\eae0";
 }

 .material-icons-photo-camera:before {
     content: "\eae1";
 }

 .material-icons-photo-filter:before {
     content: "\eae2";
 }

 .material-icons-photo-library:before {
     content: "\eae3";
 }

 .material-icons-photo-size-select-actual:before {
     content: "\eae4";
 }

 .material-icons-photo-size-select-large:before {
     content: "\eae5";
 }

 .material-icons-photo-size-select-small:before {
     content: "\eae6";
 }

 .material-icons-picture-as-pdf:before {
     content: "\eae7";
 }

 .material-icons-portrait:before {
     content: "\eae8";
 }

 .material-icons-remove-red-eye:before {
     content: "\eae9";
 }

 .material-icons-rotate-90-degrees-ccw:before {
     content: "\eaea";
 }

 .material-icons-rotate-left:before {
     content: "\eaeb";
 }

 .material-icons-rotate-right:before {
     content: "\eaec";
 }

 .material-icons-slideshow:before {
     content: "\eaed";
 }

 .material-icons-straighten:before {
     content: "\eaee";
 }

 .material-icons-style:before {
     content: "\eaef";
 }

 .material-icons-switch-camera:before {
     content: "\eaf0";
 }

 .material-icons-switch-video:before {
     content: "\eaf1";
 }

 .material-icons-tag-faces:before {
     content: "\eaf2";
 }

 .material-icons-texture:before {
     content: "\eaf3";
 }

 .material-icons-timelapse:before {
     content: "\eaf4";
 }

 .material-icons-timer-3:before {
     content: "\eaf5";
 }

 .material-icons-timer-10:before {
     content: "\eaf6";
 }

 .material-icons-timer:before {
     content: "\eaf7";
 }

 .material-icons-timer-off:before {
     content: "\eaf8";
 }

 .material-icons-tonality:before {
     content: "\eaf9";
 }

 .material-icons-transform:before {
     content: "\eafa";
 }

 .material-icons-tune:before {
     content: "\eafb";
 }

 .material-icons-view-comfy:before {
     content: "\eafc";
 }

 .material-icons-view-compact:before {
     content: "\eafd";
 }

 .material-icons-vignette:before {
     content: "\eafe";
 }

 .material-icons-wb-auto:before {
     content: "\eaff";
 }

 .material-icons-wb-cloudy:before {
     content: "\eb00";
 }

 .material-icons-wb-incandescent:before {
     content: "\eb01";
 }

 .material-icons-wb-iridescent:before {
     content: "\eb02";
 }

 .material-icons-wb-sunny:before {
     content: "\eb03";
 }

 .material-icons-cast:before {
     content: "\eb04";
 }

 .material-icons-cast-connected:before {
     content: "\eb05";
 }

 .material-icons-computer:before {
     content: "\eb06";
 }

 .material-icons-desktop-mac:before {
     content: "\eb07";
 }

 .material-icons-desktop-windows:before {
     content: "\eb08";
 }

 .material-icons-developer-board:before {
     content: "\eb09";
 }

 .material-icons-device-hub:before {
     content: "\eb0a";
 }

 .material-icons-devices-other:before {
     content: "\eb0b";
 }

 .material-icons-dock:before {
     content: "\eb0c";
 }

 .material-icons-gamepad:before {
     content: "\eb0d";
 }

 .material-icons-headset:before {
     content: "\eb0e";
 }

 .material-icons-headset-mic:before {
     content: "\eb0f";
 }

 .material-icons-keyboard:before {
     content: "\eb10";
 }

 .material-icons-keyboard-arrow-down:before {
     content: "\eb11";
 }

 .material-icons-keyboard-arrow-left:before {
     content: "\eb12";
 }

 .material-icons-keyboard-arrow-right:before {
     content: "\eb13";
 }

 .material-icons-keyboard-arrow-up:before {
     content: "\eb14";
 }

 .material-icons-keyboard-backspace:before {
     content: "\eb15";
 }

 .material-icons-keyboard-capslock:before {
     content: "\eb16";
 }

 .material-icons-keyboard-hide:before {
     content: "\eb17";
 }

 .material-icons-keyboard-return:before {
     content: "\eb18";
 }

 .material-icons-keyboard-tab:before {
     content: "\eb19";
 }

 .material-icons-keyboard-voice:before {
     content: "\eb1a";
 }

 .material-icons-laptop:before {
     content: "\eb1b";
 }

 .material-icons-laptop-chromebook:before {
     content: "\eb1c";
 }

 .material-icons-laptop-mac:before {
     content: "\eb1d";
 }

 .material-icons-laptop-windows:before {
     content: "\eb1e";
 }

 .material-icons-memory:before {
     content: "\eb1f";
 }

 .material-icons-mouse:before {
     content: "\eb20";
 }

 .material-icons-phone-android:before {
     content: "\eb21";
 }

 .material-icons-phone-iphone:before {
     content: "\eb22";
 }

 .material-icons-phonelink:before {
     content: "\eb23";
 }

 .material-icons-phonelink-off:before {
     content: "\eb24";
 }

 .material-icons-power-input:before {
     content: "\eb25";
 }

 .material-icons-router:before {
     content: "\eb26";
 }

 .material-icons-scanner:before {
     content: "\eb27";
 }

 .material-icons-qr-code:before {
     content: "\e00a";
 }

 .material-icons-security:before {
     content: "\eb28";
 }

 .material-icons-sim-card:before {
     content: "\eb29";
 }

 .material-icons-smartphone:before {
     content: "\eb2a";
 }

 .material-icons-speaker:before {
     content: "\eb2b";
 }

 .material-icons-speaker-group:before {
     content: "\eb2c";
 }

 .material-icons-tablet:before {
     content: "\eb2d";
 }

 .material-icons-tablet-android:before {
     content: "\eb2e";
 }

 .material-icons-tablet-mac:before {
     content: "\eb2f";
 }

 .material-icons-toys:before {
     content: "\eb30";
 }

 .material-icons-tv:before {
     content: "\eb31";
 }

 .material-icons-videogame-asset:before {
     content: "\eb32";
 }

 .material-icons-watch:before {
     content: "\eb33";
 }

 .material-icons-attachment:before {
     content: "\eb34";
 }

 .material-icons-cloud:before {
     content: "\eb35";
 }

 .material-icons-cloud-circle:before {
     content: "\eb36";
 }

 .material-icons-cloud-done:before {
     content: "\eb37";
 }

 .material-icons-cloud-download:before {
     content: "\eb38";
 }

 .material-icons-cloud-off:before {
     content: "\eb39";
 }

 .material-icons-cloud-queue:before {
     content: "\eb3a";
 }

 .material-icons-cloud-upload:before {
     content: "\eb3b";
 }

 .material-icons-create-new-folder:before {
     content: "\eb3c";
 }

 .material-icons-file-download:before {
     content: "\eb3d";
 }

 .material-icons-file-upload:before {
     content: "\eb3e";
 }

 .material-icons-folder:before {
     content: "\eb3f";
 }

 .material-icons-folder-open:before {
     content: "\eb40";
 }

 .material-icons-folder-shared:before {
     content: "\eb41";
 }

 .material-icons-attach-file:before {
     content: "\eb42";
 }

 .material-icons-attach-money:before {
     content: "\eb43";
 }

 .material-icons-border-all:before {
     content: "\eb44";
 }

 .material-icons-border-bottom:before {
     content: "\eb45";
 }

 .material-icons-border-clear:before {
     content: "\eb46";
 }

 .material-icons-border-horizontal:before {
     content: "\eb49";
 }

 .material-icons-border-inner:before {
     content: "\eb4a";
 }

 .material-icons-border-left:before {
     content: "\eb4b";
 }

 .material-icons-border-outer:before {
     content: "\eb4c";
 }

 .material-icons-border-right:before {
     content: "\eb4d";
 }

 .material-icons-border-style:before {
     content: "\eb4e";
 }

 .material-icons-border-top:before {
     content: "\eb4f";
 }

 .material-icons-border-vertical:before {
     content: "\eb50";
 }

 .material-icons-bubble-chart:before {
     content: "\eb51";
 }

 .material-icons-drag-handle:before {
     content: "\eb52";
 }

 .material-icons-format-align-center:before {
     content: "\eb53";
 }

 .material-icons-format-align-justify:before {
     content: "\eb54";
 }

 .material-icons-format-align-left:before {
     content: "\eb55";
 }

 .material-icons-format-align-right:before {
     content: "\eb56";
 }

 .material-icons-format-bold:before {
     content: "\eb57";
 }

 .material-icons-format-clear:before {
     content: "\eb58";
 }

 .material-icons-format-color-reset:before {
     content: "\eb5b";
 }

 .material-icons-format-indent-decrease:before {
     content: "\eb5e";
 }

 .material-icons-format-indent-increase:before {
     content: "\eb5f";
 }

 .material-icons-format-italic:before {
     content: "\eb60";
 }

 .material-icons-format-line-spacing:before {
     content: "\eb61";
 }

 .material-icons-format-list-bulleted:before {
     content: "\eb62";
 }

 .material-icons-format-list-numbered:before {
     content: "\eb63";
 }

 .material-icons-format-paint:before {
     content: "\eb64";
 }

 .material-icons-format-quote:before {
     content: "\eb65";
 }

 .material-icons-format-shapes:before {
     content: "\eb66";
 }

 .material-icons-format-size:before {
     content: "\eb67";
 }

 .material-icons-format-strikethrough:before {
     content: "\eb68";
 }

 .material-icons-format-textdirection-l-to-r:before {
     content: "\eb69";
 }

 .material-icons-format-textdirection-r-to-l:before {
     content: "\eb6a";
 }

 .material-icons-format-underlined:before {
     content: "\eb6b";
 }

 .material-icons-functions:before {
     content: "\eb6c";
 }

 .material-icons-highlight:before {
     content: "\eb6d";
 }

 .material-icons-insert-chart:before {
     content: "\eb6e";
 }

 .material-icons-insert-comment:before {
     content: "\eb6f";
 }

 .material-icons-insert-drive-file:before {
     content: "\eb70";
 }

 .material-icons-insert-emoticon:before {
     content: "\eb71";
 }

 .material-icons-insert-invitation:before {
     content: "\eb72";
 }

 .material-icons-insert-link:before {
     content: "\eb73";
 }

 .material-icons-insert-photo:before {
     content: "\eb74";
 }

 .material-icons-linear-scale:before {
     content: "\eb75";
 }

 .material-icons-merge-type:before {
     content: "\eb76";
 }

 .material-icons-mode-comment:before {
     content: "\eb77";
 }

 .material-icons-mode-edit:before {
     content: "\eb78";
 }

 .material-icons-monetization-on:before {
     content: "\eb79";
 }

 .material-icons-money-off:before {
     content: "\eb7a";
 }

 .material-icons-multiline-chart:before {
     content: "\eb7b";
 }

 .material-icons-pie-chart:before {
     content: "\eb7c";
 }

 .material-icons-pie-chart-outlined:before {
     content: "\eb7d";
 }

 .material-icons-publish:before {
     content: "\eb7e";
 }

 .material-icons-short-text:before {
     content: "\eb7f";
 }

 .material-icons-show-chart:before {
     content: "\eb80";
 }

 .material-icons-space-bar:before {
     content: "\eb81";
 }

 .material-icons-strikethrough-s:before {
     content: "\eb82";
 }

 .material-icons-text-fields:before {
     content: "\eb83";
 }

 .material-icons-title:before {
     content: "\eb84";
 }

 .material-icons-vertical-align-bottom:before {
     content: "\eb85";
 }

 .material-icons-vertical-align-center:before {
     content: "\eb86";
 }

 .material-icons-vertical-align-top:before {
     content: "\eb87";
 }

 .material-icons-wrap-text:before {
     content: "\eb88";
 }

 .material-icons-access-alarm:before {
     content: "\eb89";
 }

 .material-icons-access-alarms:before {
     content: "\eb8a";
 }

 .material-icons-access-time:before {
     content: "\eb8b";
 }

 .material-icons-add-alarm:before {
     content: "\eb8c";
 }

 .material-icons-airplanemode-active:before {
     content: "\eb8d";
 }

 .material-icons-airplanemode-inactive:before {
     content: "\eb8e";
 }

 .material-icons-battery-20 .path1:before {
     content: "\eb8f";
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-20 .path2:before {
     content: "\eb90";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-30 .path1:before {
     content: "\eb91";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-30 .path2:before {
     content: "\eb92";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-50 .path1:before {
     content: "\eb93";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-50 .path2:before {
     content: "\eb94";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-60 .path1:before {
     content: "\eb95";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-60 .path2:before {
     content: "\eb96";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-80 .path1:before {
     content: "\eb97";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-80 .path2:before {
     content: "\eb98";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-90 .path1:before {
     content: "\eb99";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-90 .path2:before {
     content: "\eb9a";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-alert:before {
     content: "\eb9b";
 }

 .material-icons-battery-charging-20 .path1:before {
     content: "\eb9c";
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-20 .path2:before {
     content: "\eb9d";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-30 .path1:before {
     content: "\eb9e";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-30 .path2:before {
     content: "\eb9f";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-50 .path1:before {
     content: "\eba0";
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-50 .path2:before {
     content: "\eba1";
     margin-left: -1em;
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-60 .path1:before {
     content: "\eba2";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-60 .path2:before {
     content: "\eba3";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-80 .path1:before {
     content: "\eba4";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-80 .path2:before {
     content: "\eba5";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-90 .path1:before {
     content: "\eba6";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-battery-charging-90 .path2:before {
     content: "\eba7";
     margin-left: -1em;
     color: rgb(0, 0, 0);
 }

 .material-icons-battery-charging-full:before {
     content: "\eba8";
 }

 .material-icons-battery-full:before {
     content: "\eba9";
 }

 .material-icons-battery-std:before {
     content: "\ebaa";
 }

 .material-icons-battery-unknown:before {
     content: "\ebab";
 }

 .material-icons-bluetooth:before {
     content: "\ebac";
 }

 .material-icons-bluetooth-connected:before {
     content: "\ebad";
 }

 .material-icons-bluetooth-disabled:before {
     content: "\ebae";
 }

 .material-icons-bluetooth-searching:before {
     content: "\ebaf";
 }

 .material-icons-brightness-auto:before {
     content: "\ebb0";
 }

 .material-icons-brightness-high:before {
     content: "\ebb1";
 }

 .material-icons-brightness-low:before {
     content: "\ebb2";
 }

 .material-icons-brightness-medium:before {
     content: "\ebb3";
 }

 .material-icons-data-usage:before {
     content: "\ebb4";
 }

 .material-icons-developer-mode:before {
     content: "\ebb5";
 }

 .material-icons-devices:before {
     content: "\ebb6";
 }

 .material-icons-dvr:before {
     content: "\ebb7";
 }

 .material-icons-gps-fixed:before {
     content: "\ebb8";
 }

 .material-icons-gps-not-fixed:before {
     content: "\ebb9";
 }

 .material-icons-gps-off:before {
     content: "\ebba";
 }

 .material-icons-graphic-eq:before {
     content: "\ebbb";
 }

 .material-icons-location-disabled:before {
     content: "\ebbc";
 }

 .material-icons-location-searching:before {
     content: "\ebbd";
 }

 .material-icons-nfc:before {
     content: "\ebc2";
 }

 .material-icons-screen-lock-landscape:before {
     content: "\ebc3";
 }

 .material-icons-screen-lock-portrait:before {
     content: "\ebc4";
 }

 .material-icons-screen-lock-rotation:before {
     content: "\ebc5";
 }

 .material-icons-screen-rotation:before {
     content: "\ebc6";
 }

 .material-icons-sd-storage:before {
     content: "\ebc7";
 }

 .material-icons-settings-system-daydream:before {
     content: "\ebc8";
 }

 .material-icons-signal-cellular-0-bar:before {
     content: "\ebc9";
 }

 .material-icons-signal-cellular-1-bar .path1:before {
     content: "\ebca";
     color: rgb(0, 0, 0);
     opacity: 0.3;
 }

 .material-icons-signal-cellular-4-bar:before {
     content: "\ebd0";
 }

 .material-icons-signal-cellular-no-sim:before {
     content: "\ebda";
 }

 .material-icons-signal-cellular-null:before {
     content: "\ebdb";
 }

 .material-icons-signal-cellular-off:before {
     content: "\ebdc";
 }

 .material-icons-signal-wifi-0-bar:before {
     content: "\ebdd";
 }

 .material-icons-signal-wifi-4-bar:before {
     content: "\ebec";
 }

 .material-icons-signal-wifi-4-bar-lock:before {
     content: "\ebed";
 }

 .material-icons-signal-wifi-off:before {
     content: "\ebee";
 }

 .material-icons-storage:before {
     content: "\ebef";
 }

 .material-icons-usb:before {
     content: "\ebf0";
 }

 .material-icons-wallpaper:before {
     content: "\ebf1";
 }

 .material-icons-widgets:before {
     content: "\ebf2";
 }

 .material-icons-wifi-lock:before {
     content: "\ebf3";
 }

 .material-icons-wifi-tethering:before {
     content: "\ebf4";
 }

 .material-icons-add:before {
     content: "\ebf5";
 }

 .material-icons-add-box:before {
     content: "\ebf6";
 }

 .material-icons-add-circle:before {
     content: "\ebf7";
 }

 .material-icons-add-circle-outline:before {
     content: "\ebf8";
 }

 .material-icons-archive:before {
     content: "\ebf9";
 }

 .material-icons-backspace:before {
     content: "\ebfa";
 }

 .material-icons-block:before {
     content: "\ebfb";
 }

 .material-icons-clear:before {
     content: "\ebfc";
 }

 .material-icons-content-copy:before {
     content: "\ebfd";
 }

 .material-icons-content-cut:before {
     content: "\ebfe";
 }

 .material-icons-content-paste:before {
     content: "\ebff";
 }

 .material-icons-create:before {
     content: "\ec00";
 }

 .material-icons-delete-sweep:before {
     content: "\ec01";
 }

 .material-icons-drafts:before {
     content: "\ec02";
 }

 .material-icons-filter-list:before {
     content: "\ec03";
 }

 .material-icons-flag:before {
     content: "\ec04";
 }

 .material-icons-font-download:before {
     content: "\ec05";
 }

 .material-icons-forward:before {
     content: "\ec06";
 }

 .material-icons-gesture:before {
     content: "\ec07";
 }

 .material-icons-inbox:before {
     content: "\ec08";
 }

 .material-icons-link:before {
     content: "\ec09";
 }

 .material-icons-low-priority:before {
     content: "\ec0a";
 }

 .material-icons-mail:before {
     content: "\ec0b";
 }

 .material-icons-markunread:before {
     content: "\ec0c";
 }

 .material-icons-move-to-inbox:before {
     content: "\ec0d";
 }

 .material-icons-next-week:before {
     content: "\ec0e";
 }

 .material-icons-redo:before {
     content: "\ec0f";
 }

 .material-icons-remove:before {
     content: "\ec10";
 }

 .material-icons-remove-circle:before {
     content: "\ec11";
 }

 .material-icons-remove-circle-outline:before {
     content: "\ec12";
 }

 .material-icons-reply:before {
     content: "\ec13";
 }

 .material-icons-reply-all:before {
     content: "\ec14";
 }

 .material-icons-report:before {
     content: "\ec15";
 }

 .material-icons-save:before {
     content: "\ec16";
 }

 .material-icons-select-all:before {
     content: "\ec17";
 }

 .material-icons-send:before {
     content: "\ec18";
 }

 .material-icons-sort:before {
     content: "\ec19";
 }

 .material-icons-text-format:before {
     content: "\ec1a";
 }

 .material-icons-unarchive:before {
     content: "\ec1b";
 }

 .material-icons-undo:before {
     content: "\ec1c";
 }

 .material-icons-weekend:before {
     content: "\ec1d";
 }

 .material-icons-business:before {
     content: "\ec1e";
 }

 .material-icons-call:before {
     content: "\ec1f";
 }

 .material-icons-call-end:before {
     content: "\ec20";
 }

 .material-icons-call-made:before {
     content: "\ec21";
 }

 .material-icons-call-merge:before {
     content: "\ec22";
 }

 .material-icons-call-missed:before {
     content: "\ec23";
 }

 .material-icons-call-missed-outgoing:before {
     content: "\ec24";
 }

 .material-icons-call-received:before {
     content: "\ec25";
 }

 .material-icons-call-split:before {
     content: "\ec26";
 }

 .material-icons-chat:before {
     content: "\ec27";
 }

 .material-icons-chat-bubble:before {
     content: "\ec28";
 }

 .material-icons-chat-bubble-outline:before {
     content: "\ec29";
 }

 .material-icons-clear-all:before {
     content: "\ec2a";
 }

 .material-icons-comment:before {
     content: "\ec2b";
 }

 .material-icons-contact-mail:before {
     content: "\ec2c";
 }

 .material-icons-contact-phone:before {
     content: "\ec2d";
 }

 .material-icons-contacts:before {
     content: "\ec2e";
 }

 .material-icons-dialer-sip:before {
     content: "\ec2f";
 }

 .material-icons-dialpad:before {
     content: "\ec30";
 }

 .material-icons-email:before {
     content: "\ec31";
 }

 .material-icons-forum:before {
     content: "\ec32";
 }

 .material-icons-import-contacts:before {
     content: "\ec33";
 }

 .material-icons-import-export:before {
     content: "\ec34";
 }

 .material-icons-invert-colors-off:before {
     content: "\ec35";
 }

 .material-icons-live-help:before {
     content: "\ec36";
 }

 .material-icons-location-off:before {
     content: "\ec37";
 }

 .material-icons-location-on:before {
     content: "\ec38";
 }

 .material-icons-mail-outline:before {
     content: "\ec39";
 }

 .material-icons-message:before {
     content: "\ec3a";
 }

 .material-icons-no-sim:before {
     content: "\ec3b";
 }

 .material-icons-phone:before {
     content: "\ec3c";
 }

 .material-icons-phonelink-erase:before {
     content: "\ec3d";
 }

 .material-icons-phonelink-lock:before {
     content: "\ec3e";
 }

 .material-icons-phonelink-ring:before {
     content: "\ec3f";
 }

 .material-icons-phonelink-setup:before {
     content: "\ec40";
 }

 .material-icons-portable-wifi-off:before {
     content: "\ec41";
 }

 .material-icons-present-to-all:before {
     content: "\ec42";
 }

 .material-icons-ring-volume:before {
     content: "\ec43";
 }

 .material-icons-rss-feed:before {
     content: "\ec44";
 }

 .material-icons-screen-share:before {
     content: "\ec45";
 }

 .material-icons-speaker-phone:before {
     content: "\ec46";
 }

 .material-icons-stay-current-landscape:before {
     content: "\ec47";
 }

 .material-icons-stay-current-portrait:before {
     content: "\ec48";
 }

 .material-icons-stay-primary-landscape:before {
     content: "\ec49";
 }

 .material-icons-stay-primary-portrait:before {
     content: "\ec4a";
 }

 .material-icons-stop-screen-share:before {
     content: "\ec4b";
 }

 .material-icons-swap-calls:before {
     content: "\ec4c";
 }

 .material-icons-textsms:before {
     content: "\ec4d";
 }

 .material-icons-voicemail:before {
     content: "\ec4e";
 }

 .material-icons-vpn-key:before {
     content: "\ec4f";
 }

 .material-icons-add-to-queue:before {
     content: "\ec50";
 }

 .material-icons-airplay:before {
     content: "\ec51";
 }

 .material-icons-album:before {
     content: "\ec52";
 }

 .material-icons-art-track:before {
     content: "\ec53";
 }

 .material-icons-av-timer:before {
     content: "\ec54";
 }

 .material-icons-branding-watermark:before {
     content: "\ec55";
 }

 .material-icons-call-to-action:before {
     content: "\ec56";
 }

 .material-icons-closed-caption:before {
     content: "\ec57";
 }

 .material-icons-equalizer:before {
     content: "\ec58";
 }

 .material-icons-explicit:before {
     content: "\ec59";
 }

 .material-icons-fast-forward:before {
     content: "\ec5a";
 }

 .material-icons-fast-rewind:before {
     content: "\ec5b";
 }

 .material-icons-featured-play-list:before {
     content: "\ec5c";
 }

 .material-icons-featured-video:before {
     content: "\ec5d";
 }

 .material-icons-fiber-dvr:before {
     content: "\ec5e";
 }

 .material-icons-fiber-manual-record:before {
     content: "\ec5f";
 }

 .material-icons-fiber-new:before {
     content: "\ec60";
 }

 .material-icons-fiber-pin:before {
     content: "\ec61";
 }

 .material-icons-fiber-smart-record:before {
     content: "\ec62";
 }

 .material-icons-forward-5:before {
     content: "\ec63";
 }

 .material-icons-forward-10:before {
     content: "\ec64";
 }

 .material-icons-forward-30:before {
     content: "\ec65";
 }

 .material-icons-games:before {
     content: "\ec66";
 }

 .material-icons-hd:before {
     content: "\ec67";
 }

 .material-icons-hearing:before {
     content: "\ec68";
 }

 .material-icons-high-quality:before {
     content: "\ec69";
 }

 .material-icons-library-add:before {
     content: "\ec6a";
 }

 .material-icons-library-books:before {
     content: "\ec6b";
 }

 .material-icons-library-music:before {
     content: "\ec6c";
 }

 .material-icons-loop:before {
     content: "\ec6d";
 }

 .material-icons-mic:before {
     content: "\ec6e";
 }

 .material-icons-mic-none:before {
     content: "\ec6f";
 }

 .material-icons-mic-off:before {
     content: "\ec70";
 }

 .material-icons-movie:before {
     content: "\ec71";
 }

 .material-icons-music-video:before {
     content: "\ec72";
 }

 .material-icons-new-releases:before {
     content: "\ec73";
 }

 .material-icons-not-interested:before {
     content: "\ec74";
 }

 .material-icons-note:before {
     content: "\ec75";
 }

 .material-icons-pause:before {
     content: "\ec76";
 }

 .material-icons-pause-circle-filled:before {
     content: "\ec77";
 }

 .material-icons-pause-circle-outline:before {
     content: "\ec78";
 }

 .material-icons-play-arrow:before {
     content: "\ec79";
 }

 .material-icons-play-circle-filled:before {
     content: "\ec7a";
 }

 .material-icons-play-circle-outline:before {
     content: "\ec7b";
 }

 .material-icons-playlist-add:before {
     content: "\ec7c";
 }

 .material-icons-playlist-add-check:before {
     content: "\ec7d";
 }

 .material-icons-playlist-play:before {
     content: "\ec7e";
 }

 .material-icons-queue:before {
     content: "\ec7f";
 }

 .material-icons-queue-music:before {
     content: "\ec80";
 }

 .material-icons-queue-play-next:before {
     content: "\ec81";
 }

 .material-icons-radio:before {
     content: "\ec82";
 }

 .material-icons-recent-actors:before {
     content: "\ec83";
 }

 .material-icons-remove-from-queue:before {
     content: "\ec84";
 }

 .material-icons-repeat:before {
     content: "\ec85";
 }

 .material-icons-repeat-one:before {
     content: "\ec86";
 }

 .material-icons-replay-5:before {
     content: "\ec87";
 }

 .material-icons-replay-10:before {
     content: "\ec88";
 }

 .material-icons-replay:before {
     content: "\ec89";
 }

 .material-icons-replay-30:before {
     content: "\ec8a";
 }

 .material-icons-shuffle:before {
     content: "\ec8b";
 }

 .material-icons-skip-next:before {
     content: "\ec8c";
 }

 .material-icons-skip-previous:before {
     content: "\ec8d";
 }

 .material-icons-slow-motion-video:before {
     content: "\ec8e";
 }

 .material-icons-snooze:before {
     content: "\ec8f";
 }

 .material-icons-sort-by-alpha:before {
     content: "\ec90";
 }

 .material-icons-stop:before {
     content: "\ec91";
 }

 .material-icons-subscriptions:before {
     content: "\ec92";
 }

 .material-icons-subtitles:before {
     content: "\ec93";
 }

 .material-icons-surround-sound:before {
     content: "\ec94";
 }

 .material-icons-video-call:before {
     content: "\ec95";
 }

 .material-icons-video-label:before {
     content: "\ec96";
 }

 .material-icons-video-library:before {
     content: "\ec97";
 }

 .material-icons-videocam:before {
     content: "\ec98";
 }

 .material-icons-videocam-off:before {
     content: "\ec99";
 }

 .material-icons-volume-down:before {
     content: "\ec9a";
 }

 .material-icons-volume-mute:before {
     content: "\ec9b";
 }

 .material-icons-volume-off:before {
     content: "\ec9c";
 }

 .material-icons-volume-up:before {
     content: "\ec9d";
 }

 .material-icons-web:before {
     content: "\ec9e";
 }

 .material-icons-web-asset:before {
     content: "\ec9f";
 }

 .material-icons-add-alert:before {
     content: "\eca0";
 }

 .material-icons-error:before {
     content: "\eca1";
 }

 .material-icons-error-outline:before {
     content: "\eca2";
 }

 .material-icons-warning:before {
     content: "\eca3";
 }

 .material-icons-3d-rotation:before {
     content: "\eca4";
 }

 .material-icons-accessibility:before {
     content: "\eca5";
 }

 .material-icons-accessible:before {
     content: "\eca6";
 }

 .material-icons-account-balance:before {
     content: "\eca7";
 }

 .material-icons-account-balance-wallet:before {
     content: "\eca8";
 }

 .material-icons-account-box:before {
     content: "\eca9";
 }

 .material-icons-account-circle:before {
     content: "\ecaa";
 }

 .material-icons-add-shopping-cart:before {
     content: "\ecab";
 }

 .material-icons-alarm:before {
     content: "\ecac";
 }

 .material-icons-alarm-add:before {
     content: "\ecad";
 }

 .material-icons-alarm-off:before {
     content: "\ecae";
 }

 .material-icons-alarm-on:before {
     content: "\ecaf";
 }

 .material-icons-all-out:before {
     content: "\ecb0";
 }

 .material-icons-android:before {
     content: "\ecb1";
 }

 .material-icons-announcement:before {
     content: "\ecb2";
 }

 .material-icons-aspect-ratio:before {
     content: "\ecb3";
 }

 .material-icons-assessment:before {
     content: "\ecb4";
 }

 .material-icons-assignment:before {
     content: "\ecb5";
 }

 .material-icons-assignment-ind:before {
     content: "\ecb6";
 }

 .material-icons-assignment-late:before {
     content: "\ecb7";
 }

 .material-icons-assignment-return:before {
     content: "\ecb8";
 }

 .material-icons-assignment-returned:before {
     content: "\ecb9";
 }

 .material-icons-assignment-turned-in:before {
     content: "\ecba";
 }

 .material-icons-autorenew:before {
     content: "\ecbb";
 }

 .material-icons-backup:before {
     content: "\ecbc";
 }

 .material-icons-book:before {
     content: "\ecbd";
 }

 .material-icons-bookmark:before {
     content: "\ecbe";
 }

 .material-icons-bookmark-border:before {
     content: "\ecbf";
 }

 .material-icons-bug-report:before {
     content: "\ecc0";
 }

 .material-icons-build:before {
     content: "\ecc1";
 }

 .material-icons-cached:before {
     content: "\ecc2";
 }

 .material-icons-camera-enhance:before {
     content: "\ecc3";
 }

 .material-icons-card-giftcard:before {
     content: "\ecc4";
 }

 .material-icons-card-membership:before {
     content: "\ecc5";
 }

 .material-icons-card-travel:before {
     content: "\ecc6";
 }

 .material-icons-change-history:before {
     content: "\ecc7";
 }

 .material-icons-check-circle:before {
     content: "\ecc8";
 }

 .material-icons-chrome-reader-mode:before {
     content: "\ecc9";
 }

 .material-icons-class:before {
     content: "\ecca";
 }

 .material-icons-code:before {
     content: "\eccb";
 }

 .material-icons-compare-arrows:before {
     content: "\eccc";
 }

 .material-icons-copyright:before {
     content: "\eccd";
 }

 .material-icons-credit-card:before {
     content: "\ecce";
 }

 .material-icons-dashboard:before {
     content: "\eccf";
 }

 .material-icons-date-range:before {
     content: "\ecd0";
 }

 .material-icons-delete:before {
     content: "\ecd1";
 }

 .material-icons-delete-forever:before {
     content: "\ecd2";
 }

 .material-icons-description:before {
     content: "\ecd3";
 }

 .material-icons-dns:before {
     content: "\ecd4";
 }

 .material-icons-done:before {
     content: "\ecd5";
 }

 .material-icons-done-all:before {
     content: "\ecd6";
 }

 .material-icons-donut-large:before {
     content: "\ecd7";
 }

 .material-icons-donut-small:before {
     content: "\ecd8";
 }

 .material-icons-eject:before {
     content: "\ecd9";
 }

 .material-icons-euro-symbol:before {
     content: "\ecda";
 }

 .material-icons-event:before {
     content: "\ecdb";
 }

 .material-icons-event-seat:before {
     content: "\ecdc";
 }

 .material-icons-exit-to-app:before {
     content: "\ecdd";
 }

 .material-icons-explore:before {
     content: "\ecde";
 }

 .material-icons-extension:before {
     content: "\ecdf";
 }

 .material-icons-face:before {
     content: "\ece0";
 }

 .material-icons-favorite:before {
     content: "\ece1";
 }

 .material-icons-favorite-border:before {
     content: "\ece2";
 }

 .material-icons-feedback:before {
     content: "\ece3";
 }

 .material-icons-find-in-page:before {
     content: "\ece4";
 }

 .material-icons-find-replace:before {
     content: "\ece5";
 }

 .material-icons-fingerprint:before {
     content: "\ece6";
 }

 .material-icons-flight-land:before {
     content: "\ece7";
 }

 .material-icons-flight-takeoff:before {
     content: "\ece8";
 }

 .material-icons-flip-to-back:before {
     content: "\ece9";
 }

 .material-icons-flip-to-front:before {
     content: "\ecea";
 }

 .material-icons-g-translate:before {
     content: "\eceb";
 }

 .material-icons-gavel:before {
     content: "\ecec";
 }

 .material-icons-get-app:before {
     content: "\eced";
 }

 .material-icons-gif:before {
     content: "\ecee";
 }

 .material-icons-grade:before {
     content: "\ecef";
 }

 .material-icons-group-work:before {
     content: "\ecf0";
 }

 .material-icons-help:before {
     content: "\ecf1";
 }

 .material-icons-help-outline:before {
     content: "\ecf2";
 }

 .material-icons-highlight-off:before {
     content: "\ecf3";
 }

 .material-icons-history:before {
     content: "\ecf4";
 }

 .material-icons-home:before {
     content: "\ecf5";
 }

 .material-icons-hourglass-empty:before {
     content: "\ecf6";
 }

 .material-icons-hourglass-full:before {
     content: "\ecf7";
 }

 .material-icons-http:before {
     content: "\ecf8";
 }

 .material-icons-https:before {
     content: "\ecf9";
 }

 .material-icons-important-devices:before {
     content: "\ecfa";
 }

 .material-icons-info:before {
     content: "\ecfb";
 }

 .material-icons-info-outline:before {
     content: "\ecfc";
 }

 .material-icons-input:before {
     content: "\ecfd";
 }

 .material-icons-invert-colors:before {
     content: "\ecfe";
 }

 .material-icons-label:before {
     content: "\ecff";
 }

 .material-icons-label-outline:before {
     content: "\ed00";
 }

 .material-icons-language:before {
     content: "\ed01";
 }

 .material-icons-launch:before {
     content: "\ed02";
 }

 .material-icons-lightbulb-outline:before {
     content: "\ed03";
 }

 .material-icons-line-style:before {
     content: "\ed04";
 }

 .material-icons-line-weight:before {
     content: "\ed05";
 }

 .material-icons-list:before {
     content: "\ed06";
 }

 .material-icons-lock:before {
     content: "\ed07";
 }

 .material-icons-lock-open:before {
     content: "\ed08";
 }

 .material-icons-lock-outline:before {
     content: "\ed09";
 }

 .material-icons-loyalty:before {
     content: "\ed0a";
 }

 .material-icons-markunread-mailbox:before {
     content: "\ed0b";
 }

 .material-icons-motorcycle:before {
     content: "\ed0c";
 }

 .material-icons-note-add:before {
     content: "\ed0d";
 }

 .material-icons-offline-pin:before {
     content: "\ed0e";
 }

 .material-icons-opacity:before {
     content: "\ed0f";
 }

 .material-icons-open-in-browser:before {
     content: "\ed10";
 }

 .material-icons-open-in-new:before {
     content: "\ed11";
 }

 .material-icons-open-with:before {
     content: "\ed12";
 }

 .material-icons-pageview:before {
     content: "\ed13";
 }

 .material-icons-pan-tool:before {
     content: "\ed14";
 }

 .material-icons-payment:before {
     content: "\ed15";
 }

 .material-icons-perm-camera-mic:before {
     content: "\ed16";
 }

 .material-icons-perm-contact-calendar:before {
     content: "\ed17";
 }

 .material-icons-perm-data-setting:before {
     content: "\ed18";
 }

 .material-icons-perm-device-information:before {
     content: "\ed19";
 }

 .material-icons-perm-identity:before {
     content: "\ed1a";
 }

 .material-icons-perm-media:before {
     content: "\ed1b";
 }

 .material-icons-perm-phone-msg:before {
     content: "\ed1c";
 }

 .material-icons-perm-scan-wifi:before {
     content: "\ed1d";
 }

 .material-icons-pets:before {
     content: "\ed1e";
 }

 .material-icons-picture-in-picture:before {
     content: "\ed1f";
 }

 .material-icons-picture-in-picture-alt:before {
     content: "\ed20";
 }

 .material-icons-play-for-work:before {
     content: "\ed21";
 }

 .material-icons-polymer:before {
     content: "\ed22";
 }

 .material-icons-power-settings-new:before {
     content: "\ed23";
 }

 .material-icons-pregnant-woman:before {
     content: "\ed24";
 }

 .material-icons-print:before {
     content: "\ed25";
 }

 .material-icons-query-builder:before {
     content: "\ed26";
 }

 .material-icons-question-answer:before {
     content: "\ed27";
 }

 .material-icons-receipt:before {
     content: "\ed28";
 }

 .material-icons-record-voice-over:before {
     content: "\ed29";
 }

 .material-icons-redeem:before {
     content: "\ed2a";
 }

 .material-icons-remove-shopping-cart:before {
     content: "\ed2b";
 }

 .material-icons-reorder:before {
     content: "\ed2c";
 }

 .material-icons-report-problem:before {
     content: "\ed2d";
 }

 .material-icons-restore:before {
     content: "\ed2e";
 }

 .material-icons-restore-page:before {
     content: "\ed2f";
 }

 .material-icons-room:before {
     content: "\ed30";
 }

 .material-icons-rounded-corner:before {
     content: "\ed31";
 }

 .material-icons-rowing:before {
     content: "\ed32";
 }

 .material-icons-schedule:before {
     content: "\ed33";
 }

 .material-icons-search:before {
     content: "\ed34";
 }

 .material-icons-settings:before {
     content: "\ed35";
 }

 .material-icons-settings-applications:before {
     content: "\ed36";
 }

 .material-icons-settings-backup-restore:before {
     content: "\ed37";
 }

 .material-icons-settings-bluetooth:before {
     content: "\ed38";
 }

 .material-icons-settings-brightness:before {
     content: "\ed39";
 }

 .material-icons-settings-cell:before {
     content: "\ed3a";
 }

 .material-icons-settings-ethernet:before {
     content: "\ed3b";
 }

 .material-icons-settings-input-antenna:before {
     content: "\ed3c";
 }

 .material-icons-settings-input-component:before {
     content: "\ed3d";
 }

 .material-icons-settings-input-composite:before {
     content: "\ed3e";
 }

 .material-icons-settings-input-hdmi:before {
     content: "\ed3f";
 }

 .material-icons-settings-input-svideo:before {
     content: "\ed40";
 }

 .material-icons-settings-overscan:before {
     content: "\ed41";
 }

 .material-icons-settings-phone:before {
     content: "\ed42";
 }

 .material-icons-settings-power:before {
     content: "\ed43";
 }

 .material-icons-settings-remote:before {
     content: "\ed44";
 }

 .material-icons-settings-voice:before {
     content: "\ed45";
 }

 .material-icons-shop:before {
     content: "\ed46";
 }

 .material-icons-shop-two:before {
     content: "\ed47";
 }

 .material-icons-shopping-basket:before {
     content: "\ed48";
 }

 .material-icons-shopping-cart:before {
     content: "\ed49";
 }

 .material-icons-speaker-notes:before {
     content: "\ed4a";
 }

 .material-icons-speaker-notes-off:before {
     content: "\ed4b";
 }

 .material-icons-spellcheck:before {
     content: "\ed4c";
 }

 .material-icons-stars:before {
     content: "\ed4d";
 }

 .material-icons-store:before {
     content: "\ed4e";
 }

 .material-icons-subject:before {
     content: "\ed4f";
 }

 .material-icons-supervisor-account:before {
     content: "\ed50";
 }

 .material-icons-swap-horiz:before {
     content: "\ed51";
 }

 .material-icons-swap-vert:before {
     content: "\ed52";
 }

 .material-icons-swap-vertical-circle:before {
     content: "\ed53";
 }

 .material-icons-system-update-alt:before {
     content: "\ed54";
 }

 .material-icons-tab:before {
     content: "\ed55";
 }

 .material-icons-tab-unselected:before {
     content: "\ed56";
 }

 .material-icons-theaters:before {
     content: "\ed57";
 }

 .material-icons-thumb-down:before {
     content: "\ed58";
 }

 .material-icons-thumb-up:before {
     content: "\ed59";
 }

 .material-icons-thumbs-up-down:before {
     content: "\ed5a";
 }

 .material-icons-timeline:before {
     content: "\ed5b";
 }

 .material-icons-toc:before {
     content: "\ed5c";
 }

 .material-icons-today:before {
     content: "\ed5d";
 }

 .material-icons-toll:before {
     content: "\ed5e";
 }

 .material-icons-touch-app:before {
     content: "\ed5f";
 }

 .material-icons-track-changes:before {
     content: "\ed60";
 }

 .material-icons-translate:before {
     content: "\ed61";
 }

 .material-icons-trending-down:before {
     content: "\ed62";
 }

 .material-icons-trending-flat:before {
     content: "\ed63";
 }

 .material-icons-trending-up:before {
     content: "\ed64";
 }

 .material-icons-turned-in:before {
     content: "\ed65";
 }

 .material-icons-turned-in-not:before {
     content: "\ed66";
 }

 .material-icons-update:before {
     content: "\ed67";
 }

 .material-icons-verified-user:before {
     content: "\ed68";
 }

 .material-icons-view-agenda:before {
     content: "\ed69";
 }

 .material-icons-view-array:before {
     content: "\ed6a";
 }

 .material-icons-view-carousel:before {
     content: "\ed6b";
 }

 .material-icons-view-column:before {
     content: "\ed6c";
 }

 .material-icons-view-day:before {
     content: "\ed6d";
 }

 .material-icons-view-headline:before {
     content: "\ed6e";
 }

 .material-icons-view-list:before {
     content: "\ed6f";
 }

 .material-icons-view-module:before {
     content: "\ed70";
 }

 .material-icons-view-quilt:before {
     content: "\ed71";
 }

 .material-icons-view-stream:before {
     content: "\ed72";
 }

 .material-icons-view-week:before {
     content: "\ed73";
 }

 .material-icons-visibility:before {
     content: "\ed74";
 }

 .material-icons-visibility-off:before {
     content: "\ed75";
 }

 .material-icons-watch-later:before {
     content: "\ed76";
 }

 .material-icons-work:before {
     content: "\ed77";
 }

 .material-icons-youtube-searched-for:before {
     content: "\ed78";
 }

 .material-icons-zoom-in:before {
     content: "\ed79";
 }

 .material-icons-zoom-out:before {
     content: "\ed7a";
 }

 .material-icons-toggle-on:before {
     content: "\ed7b";
 }

 .material-icons-drag-indicator:before {
     content: "\ed7c";
 }