/* You can add global styles to this file, and also import other style files */


/*.intellisense-wrraper .CodeMirror-sizer {
  margin-left: 25px !important;
}

.intellisense-wrraper .CodeMirror-gutter-wrapper {
  left: -45px !important;
}

.CodeMirror-hints,
.CodeMirror-Tern-tooltip,
.CodeMirror-Tern-hint-doc {
  z-index: 99999 !important;
}

.intellisense-wrraper .CodeMirror-gutter.CodeMirror-linenumbers {
  width: 12px !important;
}

.intellisense-wrraper .CodeMirror-gutters {
  left: 0 !important;
}*/

.drool-modal-container .mat-mdc-tab-body-content {
    height: 400px;
}

.mat-mdc-tab-body-content{
    height: auto !important;
    overflow: unset !important;
}

/*.pending-state-label-color {
  background-color: #ff9900;
}

.completed-state-label-color {
  background-color: #32cd32;
}

html,
body,
#cesiumContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}*/

rt-div,
rt-div-minify,
rt-div-list,
rt-card,
rt-card-list,
rt-mobi-div-control,
rt-select,
rt-pb-multi-select,
rt-date,
rt-datetime,
rt-mobi-list-div-control,
rt-mobi-card-control,
rt-card-control,
rt-pb-modal-dialog,
rt-static-tab,
rt-custom-tab,
rt-static-accordion,
rt-custom-accordion,
rt-data-grid,
den-w-nav-menu,
den-w-gauge-chart,
custom-data-list,
rt-label,
rt-date-control,
den-w-pb-container-export-pdf,
rt-text-area,
rt-slider,
rt-slider-paginator,
rt-report-layout-control,
den-w-pb-tree-view,
den-w-site-app-selector,
rt-list-axis-bar-chart,
rt-line-area-list-charts,
rt-line-area-charts,
rt-list-pie-chart,
rt-stacked-vertical-bar-line,
rt-bar-line-chart-control,
rt-grouped-list-bar-chart,
rt-stacked-triangle-chart,
rt-pyramid-chart,
rt-pyramid-chart-list,
rt-tree-map,
rt-progress-bar,
rt-stepper-control,
rt-signature-pad-control,
rt-report-header,
rt-report-footer{
    display: flex;
}

rt-div-list-pagination,
den-w-child-page-control {
    display: block;
}

.hidden-control {
    background-color: lightgray;
    height: 20px;
    width: 20px;
}

.page-builder-view-container .disable-edit {
    display: none;
}

.dock-right-wrapper {
    width: 73.5%;
    margin-left: 1.5%;
}

.manage-user-task-wrapper .dock-right-wrapper {
    width: 70.5%;
    margin-left: 1.5%;
}

.dock-left-wrapper {
    width: 28%;
}

.dock-left-close .dock-left-header {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding: 10px 0 20px;
}

.dock-left-close .dock-left-wrapper,
.dock-left-header {
    display: none;
}

.dock-left-close .dock-right-wrapper {
    width: 99%;
    margin-left: 1%;
}

.color-picker.open {
    // left: 0 !important;
    // top: 20px !important;
    // position: absolute !important;
}

.disable-flex-right-scroll .flex-grid-right-content {
    overflow: hidden !important;
}


/*.vertical-tab-group .mat-mdc-tab-active,
.display-flex-icons:hover {
  @extend .rt-selected;
}

#displayMonthAsRowWise .calendar-selected-date {
  @extend .rt-selected;
}*/

.pb-clickable-control * {
    pointer-events: none;
}

.override-icon-badge-content .mat-badge-content {
    display: grid !important;
    position: absolute !important;
    width: inherit !important;
    height: inherit !important;
    line-height: inherit !important;
    text-align: center !important;
    align-items: center !important;
    font-size: inherit !important;
    // top: -15px !important;
    // right: -6px !important;
}

.override-image-badge-content .mat-badge-content {
    display: grid !important;
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
    text-align: center !important;
    align-items: center !important;
    top: -25px !important;
    right: -15px !important;
}

.rt-help-icon {
    position: absolute;
    top: 23px;
    right: 0px;
}

.rt-mt-13 {
    margin-top: 13px;
}

.cesium-viewer-bottom {
    display: none !important;
}


/* Time and Date Picker Styles */

.daterange-container {
    min-width: 500px !important;
}

.timepicker-wrapper {
    position: relative;
    top: -10px;
}

.timepicker-wrapper input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    width: 50px;
    height: 30px;
    text-align: center;
    border: 2px solid #f2f2f2;
}

.timepicker-wrapper input[type="number"]::-webkit-inner-spin-button,
.timepicker-wrapper input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.timer-menu:hover .mat-button-focus-overlay {
    background-color: transparent !important;
    opacity: 0 !important;
}

.timer-icon {
    position: absolute;
    bottom: 20px;
    right: 2px;
    cursor: pointer;
}

rt-formly-field-time .timer-icon {
    bottom: 28px;
    right: 5px;
}

.time-container {
    margin-top: -15px;
}

.time-container table {
    border-spacing: 0px !important;
}

.time-container .table .tbody tr td .mat-mdc-icon-button {
    height: 20px !important;
    line-height: 20px !important;
    position: relative;
    top: 5px;
}

.time-container .table .tbody tr td .mat-mdc-form-field {
    height: 40px !important;
}

.time-container .mat-mdc-menu-content:not(:empty) {
    padding-top: 0 !important;
}

.flex-dock-arrow {
    padding: 8px 0;
    color: #666;
}

.label-status {
    color: #fff;
    text-align: center;
    padding: 3px 10px;
    font-size: 11px;
}

.dock-panel {
    cursor: pointer;
    background: #f2f2f2;
    color: #666;
    width: 24px;
    height: 38px;
    padding-top: 6px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dock-panel-txt {
    cursor: pointer;
    background: #f2f2f2;
    color: #666;
    width: 24px;
    padding: 8px 0;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    letter-spacing: 0.6px;
}


/*chart styles*/

.rt-chart-legend-top {
    position: relative;
    top: -40px;
}

.ngx-charts-tooltip-content {
    z-index: 999998 !important;
}

.ngx-charts-tooltip-content.type-popover .tooltip-caret,
.ngx-charts-tooltip-content .tooltip-caret {
    z-index: 999999 !important;
}


/*pb canvas controls draggable styles start*/

.pb-draggable-control {
    position: relative;
}

.pb-draggable-control .control-drag-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    line-height: 25px !important;
    position: absolute;
    right: -15px;
    top: -5px;
    font-size: 14px !important;
    text-align: center;
    color: #fff;
    cursor: move !important;
}


/*pb canvas controls draggable styles end */

.annotation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    margin-left: 15px;
    margin-top: 15px;
    padding: 1em;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.5em;
    font-size: 12px;
    line-height: 1.2;
    transition: opacity 0.5s;
}

@media print {

    // label,button,.chart-legend-label{
    //     -webkit-print-color-adjust: exact;
    // }
    den-w-pb-container-export-pdf {
        outline: none;
        display: none;
    }

    @page {
        margin: 0 5px 5px 5px !important;
        padding: 0 !important;
    }

    html {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .main-wrapper {
        display: none !important;
    }

    .break-page {
        page-break-after: always !important;
    }

    body {
        display: block !important;
        position: static !important;
        overflow: visible !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .print-wrapper .draw-line,
    .cdk-overlay-container {
        display: none !important;
    }

    rt-card-list {
        margin-top: 0 !important;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.pb-loader:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner 0.6s linear infinite;
    z-index: 999;
}

.pb-loader {
    position: relative;
    display: block;
    overflow: hidden !important;
    min-height: 50px !important;
}

.readonly-input {
    opacity: 0.8;
}

.selected-color-box {
    margin: 4px 5px 4px 7px;
}

.accordion-grp-paginator .mat-mdc-paginator {
    font-size: 14px;
}

.auto-select-width {
    mat-select {
        min-width: 50px;
    }

    .mat-mdc-form-field {
        width: auto !important;
    }

    .mat-mdc-select-value {
        max-width: 100%;
        color: inherit;
    }
}


/* Formly field controls styles */

formly-field-select .formly-field-select-wrapper span.mat-mdc-form-field-label-wrapper {
    display: none;
}

.formly-field-select {
    border: none !important;
    padding: 0.1em 0 !important;
}

formly-field-select .formly-field-select-wrapper .mat-mdc-text-field-wrapper {
    padding-bottom: 0.2em;
}

.formly-field-date-wrapper .mat-mdc-icon-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.formly-field-date-wrapper .mat-mdc-icon-button svg {
    width: 18px;
    height: 18px;
    display: inline-block;
}

.rt-mt-3 {
    margin-top: 3px;
}


/* Map Control styles */

.map-control .leaflet-container {
    width: 100%;
    height: 100% !important;
    position: absolute;
    z-index: 0;
}

.map-control .leaflet-popup-content {
    margin: 8px;
}

.map-control .leaflet-popup-content-wrapper {
    border-radius: 2px;
}

.map-control .leaflet-container a.leaflet-popup-close-button {
    color: #000 !important;
    top: 6px;
    right: 8px;
}

.charvaka-bottom-model-wrapper .mat-mdc-tab-header-pagination,
.map-control .leaflet-control-zoom,
.map-control .leaflet-control-layers,
.map-control .leaflet-control-attribution {
    display: none !important;
}


/* Map Control styles */


/* Fluent builder dynamic color styles */

body.rt-pink-bluegrey-theme .mat-mdc-input-element:disabled,
body.rt-pink-bluegrey-theme .mat-mdc-select-disabled .mat-mdc-select-value {
    color: rgba(255, 255, 255, 0.65);
    /* removed !important -> fluent builder dynamic color not working */
}

body .mat-mdc-input-element:disabled,
body .mat-mdc-select-disabled .mat-mdc-select-value {
    color: rgba(0, 0, 0, 0.7);
    /* removed !important -> fluent builder dynamic color not working */
}

body.rt-pink-bluegrey-theme .mat-mdc-input-element,
body.rt-pink-bluegrey-theme .mat-mdc-select-value {
    color: rgba(255, 255, 255, 0.85);
    /* removed !important -> fluent builder dynamic color not working */
}

body .mat-mdc-input-element,
body .mat-mdc-select-value {
    color: rgba(0, 0, 0, 0.9);
    /* removed !important -> fluent builder dynamic color not working */
}

// .mat-mdc-form-field-should-float .mat-mdc-form-field-label mat-label,
// .mat-mdc-form-field-should-float .mat-mdc-form-field-label,
// .mat-mdc-form-field-infix .mat-mdc-input-element,
// .mat-mdc-select-value .mat-mdc-select-value-text {
//     font-size: 14px !important;
// }

.pb-usertask-control-wrapper app-dynamic-grid-add-update-form .container {
    min-height: 500px;
}


/* Dynamic formly Input && Input Box Layout styles*/

// .dynamic-input-layout .mat-mdc-input-element,
// .dynamic-input-layout .mat-mdc-form-field-infix,
// .input-box-layout .mat-mdc-input-element,
// .input-box-layout .mat-mdc-form-field-infix,
// .input-box-layout .mat-mdc-form-field-label {
//     padding-left: 2px;
// }

// .input-box-layout.rt-pb-input-0 .mat-mdc-form-field-appearance-legacy .mat-mdc-text-field-wrapper {
//     padding-bottom: 0;
// }

// .input-box-placeholder-layout .mat-mdc-form-field-should-float .mat-mdc-form-field-label-wrapper {
//     top: -1.1223em;
// }

// .input-box-placeholder-layout rt-formly-chips .mat-mdc-form-field-should-float .mat-mdc-form-field-label-wrapper {
//     top: -1.4223em;
// }

// .input-box-layout.input-box-placeholder-layout .mat-mdc-form-field-label {
//     display: block !important;
// }

// .input-box-layout .mat-mdc-form-field-underline,
// .input-box-layout .mat-mdc-form-field.mat-focused .mat-mdc-form-field-label,
// .input-box-layout .mat-mdc-form-field-label,
.dynamic-input-layout .mat-mdc-form-field-underline {
    display: none !important;
}

// .input-box-layout .mat-mdc-form-field-infix,
// .input-box-layout mat-form-field:not(.mat-mdc-form-field-type-checkbox) .mat-mdc-form-field-infix,
.dynamic-input-layout .mat-mdc-form-field-infix,
.dynamic-input-layout .mat-mdc-form-field-disabled .mat-mdc-form-field-infix,
.page-builder-container .enable-border {
    border: 1px solid #676d86;
    padding-left: 3px;
}

.dynamic-input-layout .mat-mdc-form-field-type-checkbox .mat-mdc-form-field-infix {
    border: 0 solid #676d86;
}

.dynamic-input-layout .mat-mdc-form-field-label-wrapper {
    top: -3.3em;
}

.dynamic-input-layout .mat-mdc-form-field-hide-placeholder .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label *,
.dynamic-input-layout .mat-checkbox-label,
.dynamic-input-layout rt-formly-map span,
.dynamic-input-layout rt-custom-formly-color-picker span,
.dynamic-input-layout span.file-label,
.dynamic-input-layout .label-field {
    font-weight: 600 !important;
    color: rgb(76 76 76 / 95%);
    font-size: 17px !important;
}

.dynamic-input-layout .label-field {
    display: block;
}

.dynamic-input-layout .view-label {
    font-weight: 600 !important;
    color: #000;
    font-size: 17px !important;
}

.dynamic-input-layout .view-layout [class*="col-md-"] {
    margin-bottom: 25px !important;
}

.dynamic-input-layout .search-filter-select.mat-mdc-form-field-hide-placeholder .mat-mdc-select-placeholder {
    color: initial;
    -webkit-text-fill-color: initial;
}

.dynamic-grid-add-update-form-wrapper {
    font-size: 16px !important;
}

.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
    transform: none;
}

.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
    color: rgba(0, 0, 0, 0.54) !important;
}

.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field-should-float .mat-mdc-form-field-label mat-label,
.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field-should-float .mat-mdc-form-field-label,
.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field-infix .mat-mdc-input-element,
.dynamic-grid-add-update-form-wrapper .mat-mdc-form-field-infix .bold-txt,
.dynamic-grid-add-update-form-wrapper .mat-mdc-select-value .mat-mdc-select-value-text {
    font-size: 18px !important;
    font-weight: 600;
}

.delete-map-icon {
    position: relative;
    top: 5px;
}

.charvaka-bottom-model-wrapper {
    position: fixed !important;
    bottom: 35px;
    left: 270px;
    width: 65%;
    height: 410px;
    overflow: auto;
}

.minified .charvaka-bottom-model-wrapper {
    left: 65px;
    width: 75%;
}

.select-searchable-options-container {
    max-height: 175px;
    overflow: scroll;
}

.searchable-arrow {
    position: absolute;
    right: -2px;
    top: 13px;
    z-index: 99;
    cursor: pointer;
}

.hide-select {
    opacity: 0;
    height: 0;
}

.pb-group-icons {
    top: -8px;
}

.whatsapp-icon {
    position: relative;
    top: -3px;
}

.task-view-dialog-wrapper .mat-mdc-dialog-actions {
    min-height: 15px;
}

.rt-min-height-20 {
    min-height: 20px !important;
}

.label-btn {
    background: #c4c4c4;
    padding: 2px 10px;
    border-radius: 5px;
}

.visually-hidden {
    position: absolute;
    left: -100vw;
}

.dimension-selector-wrapper input,
.dimension-selector .mat-mdc-select-arrow {
    border: none;
}

.dimension-selector .mat-mdc-select-arrow {
    display: none;
}

.dimension-selector .mat-mdc-select-value {
    overflow: inherit;
}

.dimension-selector .mat-mdc-select-value .mat-mdc-select-value-text {
    font-size: 11px !important;
}

.mat-mdc-select-panel {
    // min-width: 60px !important
    // min-width: calc(100% + 48px);
    // transform: scaleY(1);
}

.mat-mdc-select-panel .dimention-unit {
    padding: 6px 14px !important;
}

.height-26 {
    height: 26px;
}


/* grid area header dimension css */

.grid-header-dimension {
    position: absolute !important;
    top: 20px;
    z-index: 9;
    width: 150px;
}

.grid-row.grid-header-dimension {
    top: 35px;
    left: 20px;
}

.grid-header-dimension .dimension-selector-wrapper {
    padding-top: 2px;
}

.header-dimension-close {
    position: absolute;
    right: -5px;
    top: -6px;
}

.btn-appearance-none {
    background: none;
    border: none;
    padding: 2px;
}

.resize-bg {
    background: url("./assets/svg/resize-arrow.svg") no-repeat;
    width: 14px;
    height: 14px;
    background-size: auto;
}

.merge-resize-bg {
    background: url("./assets/svg/resize.svg") no-repeat;
    width: 14px;
    height: 14px;
    background-size: auto;
}

.heading-config-icon {
    position: absolute !important;
    width: 22px;
    height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    line-height: 28px;
}

.fixed-panel {
    position: fixed !important;
    z-index: 9999
}

.mat-mdc-form-field-type-select formly-field-select .mat-mdc-form-field-underline {
    display: block !important;
}

.mat-mdc-form-field-type-select formly-field-select .mat-mdc-text-field-wrapper {
    margin-bottom: 0 !important;
}

.mat-mdc-form-field-type-select formly-field-select .mdc-line-ripple {
    display: none !important;
}

.mat-mdc-form-field-type-select formly-field-select .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: 30px !important;
}

.full-width-height {
    width: 100%;
    height: 100%;
}

// override ngx-formly material css

formly-wrapper-mat-form-field .mat-mdc-form-field {
    width: 100%
}

formly-wrapper-mat-form-field .mat-mdc-form-field-subscript-wrapper,
formly-wrapper-mat-form-field .mat-mdc-form-field-underline {
    position: static
}

.searchanble-select-layout-wrapper input {
    border-width: 0;
    border-bottom-width: 1px;
    padding-bottom: 4px;
}

// .searchanble-select-layout-wrapper .auto-complete-arrow {
//     right: -2px !important;
// }

/* action menu css in listing pages*/

.action-menu-wrapper {
    min-height: auto !important;
}

.action-menu-wrapper den-w-disposal-icon a {
    margin-bottom: 10px !important;
}

.action-menu-wrapper .mat-mdc-menu-content {
    padding: 10px 0 0 0 !important;
}

.rt-horiz-icon {
    width: 25px !important;
    height: 25px !important;
    line-height: 25px !important;
}

.rt-lineheight-20 {
    line-height: 20px !important;
}

.rounded-search-wrapper {
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    border-radius: 20px;
}

.rt-disable-bg {
    background-color: #FFF0F0;
}

.rt-left-panel-listing-bg,
.rt-left-panel-listing-bg .mat-mdc-paginator {
    background-color: #F5F7F9 !important;
}

.refresh-bg {
    background: transparent;
    border: none;
    margin-top: 7px;
}

.input-as-txt {
    width: 30px !important;
    text-align: center;
    background: transparent !important;
    border: 0 !important;
    // color: #a6b0cf !important;
}


/* Hide Input Number Arrows */
/* Chrome, Safari, Edge, Opera */
input.input-as-txt::-webkit-outer-spin-button,
input.input-as-txt::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/* datagrid and listpagination controls pagination css */

.pagination-layout-wrapper {
    height: 42px;
}

.pagination-layout-wrapper .mat-mdc-paginator-container {
    min-height: 40px !important;
    align-items: center;
    padding: 0 0 0 8px;
}

.pagination-layout-wrapper .mat-mdc-paginator-page-size {
    margin-right: 0;
    order: 2;
}

.pagination-layout-wrapper .mat-mdc-paginator-range-label {
    margin: 0 5px 0 5px;
}

.pagination-layout-wrapper .mat-mdc-paginator-page-size-label,
.pagination-layout-wrapper .mdc-notched-outline {
    display: none;
}

.pagination-layout-wrapper mat-form-field .mat-mdc-form-field-infix {
    border: 0 !important;
    border-bottom: 1px solid #676d86 !important;
}

// mat-form-field .mat-mdc-form-field-infix {
//     min-height: 0 !important;
//     padding-top: 3px !important;
//     padding-bottom: 3px !important;
// }

// mat-form-field .mat-mdc-form-field-subscript-wrapper {
//     display: none !important;
// }

// mat-form-field .mdc-line-ripple:after,
// mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
//     border-bottom-color: transparent !important;
// }

// mat-form-field .mdc-text-field {
//     padding: 0 2px !important;
// }

/* publish and discard buttons css */
.draft-publisher-wrapper .mat-barge-icon {
    position: relative;
    top: 3px
}

.draft-publisher-wrapper .mat-barge-icon .mat-badge-content {
    width: 22px !important;
    height: 20px !important;
    line-height: 20px !important;
    padding: 0;
    font-size: 10px;
    background: red;
    top: -4px !important;
    right: -12px !important;
}


//full screen

.full-screen-mode {

    rt-newheader,
    nav,
    rt-pb-top-toolbar-panel,
    .pb-left-wrapper,
    .pb-left-wrapper-docker,
    .pb-right-slide-wrapper,
    footer {
        display: none !important;
    }

    section.content-section {
        margin-left: 0 !important
    }

    .content-wrapper {
        padding: 0
    }

    .full-screen-msg {
        animation: fullScreenMsgfadeOut 2s forwards;
        animation-delay: 5s;
        position: absolute;
        top: 4px;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99999;
        background: #252323;
        color: #fff;
        width: 250px;
        height: 40px;
        margin: 0 auto;
        padding-top: 10px;
        text-align: center;
    }

    @keyframes fullScreenMsgfadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

.pb-leaflet-label {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
    font-weight: bold !important;
    color: inherit !important;
    // font-size: 20px !important;
}


// INTROJS STYLES

.introjs-tooltip {
    border-radius: 0px !important;
}

.introjs-tooltip-title {
    font-size: 16px !important;
}

.introjs-tooltip-header {
    background-color: #6b5aec;
    color: #fff;
    padding-bottom: 10px;
}

.introjs-skipbutton {
    color: #fff !important;
}

.introjs-prevbutton {
    background-color: #fff !important;
    border-color: #6b5aec !important;
    text-shadow: none !important;
    color: #6b5aec !important;
    text-transform: uppercase;
}

.introjs-nextbutton {
    background-color: #6b5aec !important;
    text-shadow: none !important;
    color: #fff !important;
    text-transform: uppercase;
}

.introjs-disabled {
    display: none !important;
}

.introjs-tooltipReferenceLayer {
    background-color: #fff !important;
}

.introjs-helperLayer {
    border: 2px solid white !important
}

.introjs-tooltiptext ul li {
    list-style-type: disc !important;
}

// enable data grid borders 

.enable-table-borders.cdk-table td.cdk-cell,
.enable-table-borders.cdk-table th.cdk-header-cell {
  border-right-color: rgba(0, 0, 0, 0.12) !important;
}

.enable-table-borders td.cdk-cell:not(:last-child),
.enable-table-borders th.cdk-header-cell:not(:last-child) {
    border-right-width: 1px;
    border-right-style: solid;
}

.default-theme .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: white !important;
}