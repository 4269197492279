@use '@angular/material' as mat;
//@use "@angular/material/theming";
// @import '../../../../../../../node_modules/@angular/material/core/theming';
@include mat.core();

@include mat.radio-density(-1);

$custom-typography: mat.define-typography-config($font-family: "'Roboto', Helvetica Neue,sans-serif",
        $headline-1: mat.define-typography-level(32px, 48px, 500),
        $body-1: mat.define-typography-level(13px, 20px, 400, $font-family: "'Roboto',Helvetica Neue,sans-serif", $letter-spacing: normal),
        $button: mat.define-typography-level(14px, 14px, 500, $font-family: "'Roboto', Helvetica Neue,sans-serif", $letter-spacing: normal),
        $body-2: mat.define-typography-level(13px, 20px, 500, $font-family: "'Roboto', Helvetica Neue,sans-serif", $letter-spacing: normal),
        $subtitle-1: mat.define-typography-level(13px, 20px, 500, $font-family: "'Roboto',Helvetica Neue,sans-serif", $letter-spacing: normal));
/* Deep Purple theme */
// https://material.angular.io/guide/theming#applying-a-theme-to-components -- angular upgrade 15

// Refernce: https://github.com/angular/components/issues/26056
$custom-primary: (
    50: #ede8fc,
    100: #cfc6f7,
    200: #ae9ff4,
    300: #8a78f0,
    400: #6B5AED,
    500: #453de9,
    600: #3439e2,
    700: #0d31d9,
    800: #002cd3,
    900: #0021c4,
    A100: #cfc6f7,
    A200: #ae9ff4,
    A400: #6B5AED,
    A700: #0d31d9,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$deep-puple-primary: mat.define-palette(mat.$deep-purple-palette);
$deep-puple-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$deep-puple-warn: mat.define-palette(mat.$red-palette);
$deep-puple-theme: mat.define-light-theme((color: (theme-type: light,
                primary:$deep-puple-primary,
                accent: $deep-puple-accent,
                warn: $deep-puple-warn ),
            typography: $custom-typography,
        ));

//mat.define-light-theme($deep-puple-primary, $deep-puple-accent, $deep-puple-warn);


/* Indigo Pink theme */

$indigo-pink-primary: mat.define-palette($custom-primary, 400);
$indigo-pink-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$indigo-pink-warn: mat.define-palette(mat.$red-palette);
//$indigo-pink-theme: mat.define-light-theme($indigo-pink-primary, $indigo-pink-accent, $indigo-pink-warn);
$indigo-pink-theme: mat.define-light-theme((color: (primary:$indigo-pink-primary,
                accent: $indigo-pink-accent,
                warn: $indigo-pink-warn ),
            typography: $custom-typography,
        ));

/* Pink Blue Grey theme */

$pink-bluegrey-primary: mat.define-palette(mat.$blue-grey-palette, 700);
$pink-bluegrey-accent: mat.define-palette(mat.$amber-palette, A400, A100, A400);
$pink-bluegrey-warn: mat.define-palette(mat.$deep-orange-palette);
//$pink-bluegrey-theme: mat.define-dark-theme($pink-bluegrey-primary, $pink-bluegrey-accent, $pink-bluegrey-warn);

$pink-bluegrey-theme: mat.define-dark-theme((color: (primary:$pink-bluegrey-primary,
                accent: $pink-bluegrey-accent,
                warn: $pink-bluegrey-warn ),
            typography: $custom-typography,
        ));

/* Purple Green theme */

$purple-green-primary: mat.define-palette(mat.$purple-palette);
$purple-green-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);
$purple-green-warn: mat.define-palette(mat.$deep-orange-palette);
//$purple-green-theme: mat.define-light-theme($purple-green-primary, $purple-green-accent, $purple-green-warn);

$purple-green-theme: mat.define-dark-theme((color: (primary:$purple-green-primary,
                accent: $purple-green-accent,
                warn: $purple-green-warn ),
            typography: $custom-typography,
        ));


@each $themeClass,
$themeName in ("rt-deep-purple-theme", $deep-puple-theme),
("rt-indigo-pink-theme", $indigo-pink-theme),
("rt-pink-bluegrey-theme", $pink-bluegrey-theme),
("rt-purple-green-theme", $purple-green-theme) {
    .#{$themeClass} {
        @include mat.all-component-themes($themeName);
        $primary: map-get($themeName, primary);
        $accent: map-get($themeName, accent);

        .selected-row-bg,
        nav ul li.single.active,
        .cdk-table tr.cdk-row:hover {
            background-color: mat.get-color-from-palette($primary, 100) !important;
        }

        nav ul li.active>a {
            border-right: 4px solid mat.get-color-from-palette($accent) !important;
            background-color: mat.get-color-from-palette($primary) !important;
            color: #fff !important;
        }

        .rt-border-left-accent-4 {
            border-left: 4px solid mat.get-color-from-palette($accent) !important;
        }

        .rt-border-right-accent-4 {
            border-right: 4px solid mat.get-color-from-palette($accent) !important;
        }

        .pagination-left .active {
            background-color: mat.get-color-from-palette($accent) !important;
        }

        .rt-progress-secondary-icon {
            color: mat.get-color-from-palette($primary, 100);
        }

        .rt-primary-text-color,
        .accordion-grp-paginator .mat-mdc-paginator-range-label {
            color: mat.get-color-from-palette($primary) !important;
        }

        .rt-secondary-text-color {
            color: mat.get-color-from-palette($accent) !important;
        }

        .rt-primary-bg-color,
        .selected-breadcrumb-node {
            background-color: mat.get-color-from-palette($primary) !important;
        }

        .selected-breadcrumb-node.node-breadcrumb::after {
            border-left: 10px solid mat.get-color-from-palette($primary);
        }

        .rt-primary-bg-400 {
            background-color: mat.get-color-from-palette($primary, 400) !important;
        }

        .rt-selected,
        .vertical-tab-group .mdc-tab--active,
        .display-flex-icons:hover,
        #displayMonthAsRowWise .calendar-selected-date {
            background-color: mat.get-color-from-palette($primary, 100) !important;
        }

        #monthView .calendar-selected-date {
            background-color: mat.get-color-from-palette($primary, 100) !important;
        }

        .vertical-tab-group .mdc-tab--active,
        .display-flex-icons:hover {
            @extend .rt-selected;
        }

        #displayMonthAsRowWise .calendar-selected-date {
            @extend .rt-selected;
        }

        #monthView .calendar-selected-date {
            @extend .rt-selected;
        }

        .rt-primary-border-2px {
            border: 2px solid mat.get-color-from-palette($primary, 100) !important;
        }

        .rt-primary-border {
            border: 1px solid mat.get-color-from-palette($primary, 100) !important;
        }

        .rt-primary-border-dark-2px {
            border: 2px solid mat.get-color-from-palette($primary) !important;
        }


        .rt-primary-border-dark {
            border: 1px solid mat.get-color-from-palette($primary) !important;
        }

        .list-wrapper .active {
            border-right: 4px solid mat.get-color-from-palette($accent) !important;
            background-color: mat.get-color-from-palette($primary, 50) !important;
        }

        .list-wrapper .list:hover {
            background: rgba(0, 0, 0, 0.04);
        }

        .list-wrapper .active:hover {
            background-color: mat.get-color-from-palette($primary, 50) !important;
        }

        .level-map-line {
            border-left: 5px solid mat.get-color-from-palette($accent, 100) !important;
        }

        .loc-act-output-popup .leaflet-popup-tip {
            background-color: mat.get-color-from-palette($primary) !important;
        }

        // .rt-table-columns-title {
        //     color: rgba(0, 0, 0, 0.9) !important;
        // }
        // Header foreground-color (from style config) not working for datagrid control in pagebuilder so commented the above css class

        .resource-range-slider .ng5-slider .ng5-slider-bar {
            background-color: mat.get-color-from-palette($primary, 100) !important;
        }

        .resource-range-slider .ng5-slider .ng5-slider-selection {
            background-color: mat.get-color-from-palette($primary, 500) !important;
        }

        .resource-range-slider .custom-slider .ng5-slider .ng5-slider-bar {
            background-color: mat.get-color-from-palette($accent, 100) !important;
        }

        .resource-range-slider .custom-slider .ng5-slider .ng5-slider-bar.ng5-slider-selection,
        .mview-mode-container .selected-mode button {
            background-color: mat.get-color-from-palette($accent, 500) !important;
        }

        .switch-toggle-wrapper .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
            background-color: mat.get-color-from-palette($primary) !important;
        }
    }
}


/* DART THEME NAV MENU CHANGES*/

$background: map-get($pink-bluegrey-theme, background);
$primary: map-get($pink-bluegrey-theme, primary);
$accent: map-get($pink-bluegrey-theme, accent);

.rt-pink-bluegrey-theme.minified nav>ul>li>ul,
.rt-pink-bluegrey-theme.minified nav>ul>li>a>.menu-item-parent {
    background-color: mat.get-color-from-palette($background, background);
}

.rt-pink-bluegrey-theme .rt-container-bg {
    background: transparent;
}

.rt-pink-bluegrey-theme .rt-secondary-text-color {
    color: mat.get-color-from-palette($accent) !important;
}

.rt-pink-bluegrey-theme .rt-primary-text-color {
    color: mat.get-color-from-palette($primary, 100) !important;
}

.rt-pink-bluegrey-theme .rt-primary-bg-color,
.rt-pink-bluegrey-theme .rt-selected {
    background-color: mat.get-color-from-palette($primary, 700) !important;
}

.rt-primary-border-2px {
    border: 2px solid mat.get-color-from-palette($primary, 700) !important;
}

.rt-primary-border {
    border: 1px solid mat.get-color-from-palette($primary, 700) !important;
}

.rt-pink-bluegrey-theme .selected-row-bg,
.rt-pink-bluegrey-theme nav ul li.active>a,
.rt-pink-bluegrey-theme nav ul li.single.active,
.rt-pink-bluegrey-theme .list-wrapper .active,
.rt-pink-bluegrey-theme .list-wrapper .active:hover,
.rt-pink-bluegrey-theme .cdk-table tr.cdk-row:hover {
    background-color: mat.get-color-from-palette($primary, 700) !important;
}

.rt-pink-bluegrey-theme .rt-border-bottom,
.rt-pink-bluegrey-theme .cdk-table td.cdk-cell,
.rt-pink-bluegrey-theme .cdk-table th.cdk-header-cell {
    border-bottom-color: rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border-left {
    border-left: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border-right {
    border-right: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border {
    border: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border-x {
    border-left: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-border-y {
    border-top: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-fieldset {
    border: 2px dashed rgba(255, 255, 255, 0.12) !important;
}

.rt-pink-bluegrey-theme .rt-table-columns-title {
    color: rgba(255, 255, 255, 0.7) !important;
}

.rt-pink-bluegrey-theme .resource-range-slider .ng5-slider .ng5-slider-bubble {
    color: #fff;
}

.rt-pink-bluegrey-theme .resource-range-slider .ng5-slider .ng5-slider-pointer {
    background-color: #fff;
}

.rt-pink-bluegrey-theme .mat-primary .mat-option.mat-mdc-selected:not(.mat-option-disabled) {
    color: #82acc0 !important;
}


/* Define a custom typography config that overrides the font-family as well as the
 `headlines` and `body-1` levels.
 Reference: https://material.angular.io/guide/typography */
/* $font-family: "'Roboto', sans-serif", */
// $custom-typography: mat.define-typography-config(
// $font-family: "'Roboto', sans-serif",
// $headline-1: mat.define-typography-level(32px, 48px, 500, $font-family: "'Roboto', sans-serif"),
// $body-1: mat.define-typography-level(16px, 24px, 500)
//  );

/* Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($custom-typography);*/


/*  Override typography for all Angular Material, including mat-base-typography and all components.*/

//@include mat.all-component-typographies($custom-typography);

/* Override the typography in the core CSS.*/

//@include mat.core($custom-typography);



// Now we have sweet buttons with Comic Sans.