html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: inherit;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[class*="col-md-"],
[class*="col-lg-"] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 767px) {
  .col-xs-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xs-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xs-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xs-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xs-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xs-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xs-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xs-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xs-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-1 {
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}


// @media only screen and (max-width: 767px) {

//   /* For mobile phones: */
//   [class*="col-md-"] {
//     width: 100%;
//     -webkit-flex: 0 0 100%;
//     flex: 0 0 100%;
//     max-width: 100%;
//   }
// }

@media (max-width: 992px) {

  .flex-content .flex-grid-list-wrapper,
  .flex-dock-left-arrow-wrapper {
    display: none;
  }

  .flex-content .flex-grid-details-wrapper {
    width: 100%;
  }

  .rt-table-columns-title {
    min-height: 40px !important;
  }

  den-w-form den-w-css-grid .grid-container {
    grid-template: auto / auto !important;
    grid-auto-rows: auto !important;
    align-content: baseline;
  }

  den-w-form den-w-css-grid .grid-container app-area-viewer {
    grid-area: auto !important;
    display: none;
  }

  den-w-form den-w-css-grid .grid-container * {
    grid-area: auto !important;
  }
}

@media only screen and (max-width: 1368px) {
  .flex-content .hide-on-device {
    display: none;
  }

  .flex-content .flex-grid-details-wrapper {
    width: 66%;
    margin-left: 2%;
  }
}

/* Responsive Header */

// html body {
//   min-width: 1360px;
// }

@media (max-width: 1480px) {
  header .rt-font-20 {
    font-size: 18px !important;
  }

  header .logo-section {
    width: 180px !important;
  }
}

@media (max-width: 1380px) {
  header .logo-section {
    width: 170px !important;
  }

  header .rt-font-20 {
    font-size: 13px !important;
  }

  header .header-right .rt-mr-25,
  header .rt-mr-30 {
    margin-right: 12px !important;
  }

  .notification-wrapper {
    margin-right: 15px !important;
    margin-top: 12px !important;
  }

  header .mat-mdc-button,
  header .mat-mdc-raised-button {
    font-size: 11px !important;
    padding: 0 6px !important;
  }

  .pagebuilder-left-panel-wrapper .button-list .button-list-item {
    margin-left: 4px;
    font-size: 10px !important;
    padding: 0 8px !important;
    line-height: 20px !important;
  }
}

@media (max-width: 992px) {

  header [class^="svg-icons-"],
  header [class^="material-icons-"],
  header[class*="svg-icons-"],
  header [class*="material-icons-"],
  header [class*="material-icons"] {
    font-size: 16px !important;
  }

  header .header-right .rt-mr-25,
  header .rt-mr-30 {
    margin-right: 8px !important;
  }

  header .logo-section {
    width: 150px !important;
  }

  header .theme-wrapper {
    margin-top: 10px !important;
    margin-right: 5px !important;
  }

  header .alert-number {
    height: 18px;
    line-height: 18px;
    width: 18px;
    left: 11px;
    top: -2px;
  }

  header .rt-font-20 {
    font-size: 13px !important;
  }

  header .header-right .rt-mr-25,
  header .rt-mr-30 {
    margin-right: 10px !important;
  }

  .header-left label,
  .sln-name {
    width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  header .custom-logo {
    max-width: 70%;
  }

  header .profile-icon {
    border-radius: 25px;
    height: 25px;
    width: 25px;
  }

  .flex-content .flex-grid-details-wrapper {
    width: 96%;
    margin-left: 2%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  header .logo-section {
    width: 155px !important;
  }

  header .rt-font-20 {
    font-size: 12px !important;
  }

  header .custom-logo {
    max-width: 60%;
  }

  .header-left label,
  .sln-name {
    width: 100px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {

  .sln-name,
  .header-help-icon,
  .theme-wrapper {
    display: none !important;
  }
}

/* Responsive Header */