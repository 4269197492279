/* Global css list */
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@use '@angular/material' as mat;
@import 'global.scss';
@import 'responsive.scss';
@import 'style.scss';
@import 'materialoverride.css';
/* @import 'materialicon.css'; */
@import 'theme.scss';
@import 'fonts/iconfonts/customfonts/custom-icons.css';
@import 'fonts/iconfonts/materialfonts/material-icons.css';

@import 'material-icons/iconfont/material-icons.css';
@import 'material-symbols';

.phone {
    border: 40px solid #121212;
    border-width: 45px 15px;
    border-radius: 40px;
    margin: 20px auto;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-animation: fadein 2s;
    /* / Safari, Chrome and Opera > 12.1 / */
    -moz-animation: fadein 2s;
    /* / Firefox < 16 / */
    -ms-animation: fadein 2s;
    /* / Internet Explorer / */
    -o-animation: fadein 2s;
    /* / Opera < 12.1 / */
    animation: fadein 2s;
}

.phone iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.phone.landscape-frame{
    border-width: 7px 50px;
  }
