@use "@angular/material" as mat;

// @mixin theme-variables-holder($theme) {
//     $primary: map-get($theme, primary);
//     $accent: map-get($theme, accent);
//     $warn: map-get($theme, warn);
//     $foreground: map-get($theme, foreground);
// }

@mixin devum-app-theme-emitter($theme) {
    $primary-palette: map-get($theme, primary);
    $accent-palette: map-get($theme, accent);
    $warn-palette: map-get($theme, warn);
    $typography: mat.define-typography-config();

    $primary: mat.get-color-from-palette($primary-palette);
    $accent: mat.get-color-from-palette($accent-palette);
    $warn: mat.get-color-from-palette($warn-palette);

    //default-contrast. add for other key combinations if required at later stage.
    // $primary-contrast: mat.get-color-from-palette($primary, default-contrast);
    // $accent-contrast: mat.get-color-from-palette($accent, default-contrast);
    // $warn-contrast: mat.get-color-from-palette($warn, default-contrast);

    $font-family: mat.font-family($typography, "body-1");
    //$font-letter-spacing: mat.letter-spacing($typography,'body-1');

    // app-theme-emitter {
    .primary {
        color: $primary;
    }

    .accent {
        color: $accent;
    }

    .warn {
        color: $warn;
    }

    //default
    // .primary-default {
    //     color:  mat.get-color-from-palette($primary-palette);
    // }

    // .accent-default {
    //     color:  mat.get-color-from-palette($accent-palette);
    // }

    // .warn-default {
    //     color:  mat.get-color-from-palette($warn-palette);
    // }

    //lighter
    .primary-lighter {
        color:  mat.get-color-from-palette($primary-palette, lighter);
    }

    .accent-lighter {
        color:  mat.get-color-from-palette($accent-palette, lighter);
    }

    .warn-lighter {
        color:  mat.get-color-from-palette($warn-palette, lighter);
    }

    //darker
    .primary-darker {
        color:  mat.get-color-from-palette($primary-palette, darker);
    }

    .accent-darker {
        color:  mat.get-color-from-palette($accent-palette, darker);
    }

    .warn-darker {
        color:  mat.get-color-from-palette($warn-palette, darker);
    }

    //default-contrast
    .primary-default-contrast {
        color:  mat.get-color-from-palette($primary-palette, default-contrast);
    }

    .accent-default-contrast {
        color:  mat.get-color-from-palette($accent-palette, default-contrast);
    }

    .warn-default-contrast {
        color:  mat.get-color-from-palette($warn-palette, default-contrast);
    }

    //ligher-contrast
    .primary-lighter-contrast {
        color:  mat.get-color-from-palette($primary-palette, lighter-contrast);
    }

    .accent-lighter-contrast {
        color:  mat.get-color-from-palette($accent-palette, lighter-contrast);
    }

    .warn-lighter-contrast {
        color:  mat.get-color-from-palette($warn-palette, lighter-contrast);
    }

    //darker-contrast
    .primary-darker-contrast {
        color:  mat.get-color-from-palette($primary-palette, darker-contrast);
    }

    .accent-darker-contrast {
        color:  mat.get-color-from-palette($accent-palette, darker-contrast);
    }

    .warn-darker-contrast {
        color:  mat.get-color-from-palette($warn-palette, darker-contrast);
    }

    .font-family {
        // color: mat.get-color-from-palette($font-family);
    }
    // }
}
