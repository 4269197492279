@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

body,
html,
#cesiumContainer {
    position: initial;
    top: 0px !important;
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
    font-size: 13px !important;
    line-height: 1.57142857 !important;
    overflow: hidden !important;
    letter-spacing: 0.2px;
}

.rt-container-bg {
    background: #f2f2f2;
}

// .main-wrapper {
//   height: 100%;
// }
// .main-container {
//   width: 100%;
//   padding: 0px;
//   margin: 0px;
// }
// /*Header styles */
// header {
//   height: 50px;
//   line-height: 46px;
//   z-index: 1030;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
//   background: #0278bb;
//   position: fixed;
//   width: 100%;
// }
// header .mat-toolbar-single-row {
//   align-items: end;
//   height: 50px;
//   padding: 0;
// }
// .logo-section {
//   width: 200px !important; // updated for new layout
//   float: left;
//   z-index: 1000;
//   background-color: #2a3042; // added for new layout
// }
// .header-left > div {
//   height: 50px;
//   vertical-align: middle;
// }
// .logo img {
//   width: 50%;
//   padding: 11px 0 0 10px;
// }
// .mat-grid-tile .theme-circle {
//   position: relative;
//   width: 40px;
//   height: 40px !important;
//   margin: 6px;
//   border-radius: 50%;
//   overflow: hidden;
//   padding: 0 !important;
//   text-align: center;
//   text-overflow: initial;
// }
// a.menu-icon {
//   border-radius: 2px;
//   display: inline-block;
//   font-weight: 500;
//   height: 30px;
//   min-width: 30px;
//   text-align: center;
//   text-decoration: none !important;
//   color: #fff;
//   font-size: 17px;
//   margin: 10px 0 0 5px;
//   cursor: pointer !important;
// }
// #left-panel {
//   width: 200px !important;
//   z-index: 1003;
//   position: fixed;
//   top: 50px;
//   left: 0;
//   // box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
//   border-right: none !important;
//   overflow: auto;
//   background-color: #2a3042; // added for new layout
// }
// ul.menuLeft {
//   display: block;
//   float: left;
//   padding-top: 10px;
// }
// nav ul {
//   width: 100%;
//   padding: 0;
//   margin: 0;
//   font-size: 13px;
//   line-height: 0.5em;
//   list-style: none;
//   position: relative;
// }
// nav ul li a {
//   color: #464646;
//   text-transform: capitalize;
//   font-size: 12px;
//   font-weight: 600;
//   padding: 7px 5px;
//   display: block;
//   position: relative;
// }
// nav ul li a .menu-item-parent{ // added new layout
//   color: #a6b0cf !important;
//   font-size: 12px !important;
// }
// nav ul li .material-icons {
//   font-size: 22px !important;
// }
// nav > ul > li {
//   margin-bottom: 5px;
// }
// nav ul li li {
//   border-bottom: none;
//   position: relative;
// }
// nav ul li.active > a {
//   border-right: 4px solid #0099cc;
//   width: 100%;
//   background: #e3f5ff;
// }
// nav ul ul li {
//   padding-left: 25px !important;
// }
// nav > ul ul li a i {
//   font-size: 11px !important;
//   text-align: center !important;
//   vertical-align: 0 !important;
//   line-height: 1 !important;
//   width: 40px;
//   padding: 7px 0 8px 0;
// }
.collapse-menu ul {
    display: none;
}

.collapse-menu.open ul {
    display: block;
}

// nav .collapse-sign {
//   position: absolute !important;
//   right: 8px;
//   top: 5px;
// }
// .minified nav ul li ul{
//   display: none;
// }
// .minified #left-panel .nav-menu ul li.collapse-menu:hover ul {
//     // display: block !important;
//     position: absolute;
//     left: 49px;
//     top: 40px;
//     width: 199px;
//     min-height: 180px;
//     margin-top: -3px;
//     overflow: hidden;
//     background-color: #2e3548;
//     box-shadow: none;
// }
// .minified #left-panel .nav-menu li.collapse-menu:hover ul a .menu-label {
//     display: block;
//     left: 30px !important;
//     box-shadow: none;
// }
// .minified #left-panel .nav-menu li.collapse-menu:hover ul a {
//     justify-content: initial;
//     align-items: flex-end;
// }
// .collapse-sign {
//   float: right;
// }
// nav .mat-mdc-menu-item {
//   padding: 0 8px !important;
//   display: flex;
//   align-items: center;
// }
// .collapse-sign .menu-action-icon {
//   font-size: 16px !important;
//   width: 20px;
//   height: 20px;
//   margin-right: 0 !important;
//   opacity: 0.5;
// }
// .menu-action-icon:hover {
//   color: #000;
//   opacity: 1;
// }
.menuLeft [class^="svg-icons-"]:before,
.menuLeft [class*=" svg-icons-"]:before {
    font-size: 18px !important;
}

nav [class*="svg-icons-"],
nav [class*="material-icons-"] {
    overflow: hidden;
}

nav [class*="material-icons-"],
nav [class^="material-icons-"] {
    line-height: 1.35 !important;
}


/*Header Right Section */

.notification-wrapper {
    float: left;
    position: relative;
    margin: 9px 28px 0 0;
}

.noti-bell-icon {
    color: #fff;
    transition: all 0.2s ease 0s;
}

.alert-number {
    background-color: #efac56;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    height: 25px;
    line-height: 25px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    width: 25px;
    left: 16px;
    top: -6px;
}

// ul.menuLeft li>a.mat-mdc-menu-item {
//   overflow: inherit;
//   font-weight: 500;
// }

/*Minified Menu styles*/

// .minified nav > ul > li,
// .minified nav > ul > li a {
//   position: relative;
// }
// .minified #left-panel {
//   width: 50px !important;
// }
// .minified ul.menuLeft > li > a.mat-mdc-menu-item {
//   padding-left: 6px !important;
//   justify-content: center;
// }
// .minified #left-panel nav > ul > li > ul {
//   width: 240px;
//   max-height: 500px;
//   overflow-y: auto;
// }
// .minified nav > ul > li > ul {
//   display: none !important;
//   position: absolute;
//   left: 50px;
//   width: 199px;
//   z-index: 5;
//   border: 1px solid #bfbfbf;
//   min-height: 180px;
//   -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
//   -moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
//   box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
//   margin-top: -3px;
//   overflow: hidden;
//   background: #f6f6f6;
// }
// .minified nav > ul > li > a > .menu-item-parent {
//   display: none;
//   position: absolute;
//   left: 35px;
//   top: -1px;
//   height: 38px;
//   line-height: 38px;
//   z-index: 3;
//   border-left: 1px solid #bfbfbf;
//   border-top: 1px solid #d8d4d4;
//   border-bottom: 1px solid #fff;
//   padding-left: 12px;
//   box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.2);
//   border-right: 1px solid #bfbfbf;
//   width: 226px;
//   background: #f6f6f6;
// }
// .minified nav > ul > li:hover > a > .menu-item-parent,
// .minified nav > ul > li:hover > ul {
//   display: block !important;
// }
// .minified #left-panel,
// .minified nav > ul > li,
// .minified ul.menuLeft {
//   overflow: visible;
// }
// .minified #left-panel nav > ul > li > a > .menu-item-parent {
//   width: 240px;
// }
// .minified nav ul ul li {
//   padding-left: 10px !important;
// }

/* Middle Content styles */

// .content-section {
//   min-height: 100%;
//   padding-bottom: 0px;
//   margin-left: 200px;
//   background-color: transparent;
// }
// .minified .content-section {
//   margin-left: 50px !important;
// }
// .content-wrapper {
//   padding: 60px 15px 15px 15px;
// }
.sub-title {
    margin-top: 0px !important;
    font-size: 14px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    letter-spacing: 0.3px;
}


/* Form field styles */

.view-layout [class*="col-md-"] {
    margin-bottom: 16px !important;
}

.view-layout .rt-no-mb {
    margin-bottom: 0 !important;
}

.blocklabel {
    display: block;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.required:after {
    content: "*";
    color: #ff0000;
}

.rt-buttons button+button {
    margin-left: 8px;
}

.rt-btn-cancel {
    background-color: #f2f2f2 !important;
    color: #666 !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}


/*Footer styles*/

// footer {
//   height: 25px;
//   line-height: 25px;
//   border-top: 1px solid #ececec;
//   background-color: #ffffff;
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   text-align: center;
//   z-index: 99999;
// }
// .footer-section a {
//   color: #3584fc;
//   text-decoration: none;
// }

/*Scroll Bar styles */

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #ccc;
}

/* Table Styles */

.exportmenu-wrapper {
    position: absolute;
    right: 0px;
}

.no-more-actions .exportmenu-wrapper {
    display: none;
}

td.cdk-cell,
th.cdk-header-cell {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-align: left;
}

.cdk-table td.cdk-cell,
.cdk-table th.cdk-header-cell {
    border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

body .cdk-table.disable-hover tr.cdk-row:hover {
    background: transparent !important;
}

.cdk-table {
    margin: 0 !important;
    width: 100%;
    table-layout: fixed;
}

.cdk-table .cdk-header-row,
.cdk-table .cdk-row {
    min-height: auto;
}

.cdk-table .cdk-header-row {
    height: 42px;
}

.cdk-table .cdk-header-cell {
    vertical-align: top !important;
    height: auto;
    padding-top: 10px !important;
}

.cdk-table .cdk-header-cell,
.cdk-table .cdk-cell {
    //  overflow: hidden; // on click of filter icon table header flickering
    min-height: 56px;
    min-width: auto;
    padding: 0 5px;
    vertical-align: middle;
}

.rt-table-columns-title {
    min-height: 25px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
}

.table-column-title {
    font-weight: 500 !important;
    font-size: 13px !important;
    letter-spacing: 0.3px;
}


/* .filter-wrapper {
  height: auto;
  min-height: 40px;
}

.resetall-btn {
  font-size: 12px;
  font-weight: bold;
  line-height: 20px !important;
} */

.toggle-all-check-box label.mat-checkbox-layout {
    margin-top: 10px;
}

.flexcontentleft .mat-sort-header-button {
    text-align: left;
    letter-spacing: 0.3px;
}


/* Flex Content styles

.flex-grid-list-wrapper {
  width: 100%;
  display: block;
}

.flex-grid-details-wrapper {
  display: none;
  width: 66.5%;
  margin-left: 1.5%;
}

.flex-content .flex-grid-list-wrapper {
  width: 32%;
  float: left;
}

.flex-content .flex-grid-details-wrapper {
  display: block;
  float: left;
}

.flex-content .flexcontentleft .exportmenu-wrapper,
.flex-content .reset-filter-wrapper,
.flex-content .filter-wrapper,
.flex-content .mat-sort-header-container .mat-sort-header-arrow {
  display: none;
}

.flex-content .flexcontentleft .pagination-right {
  padding-right: 5px !important;
}

.flex-content .flexcontentleft .mat-sort-header-button {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  font-size: 12px;
}

.flex-content .cdk-table .cdk-header-cell label[mat-sort-header] {
  overflow: hidden;
}

.flex-content .cdk-table .cdk-cell span,
.flex-content .cdk-table .cdk-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-content .color-field-width {
  text-align: left;
}

.disable-flex-right-scroll .flex-grid-right-content {
  overflow: hidden !important;
} */


/* .flex-content .flexcontentleft .delete-action-icon {
  display: none;
} */


/* Hide show columns on Main grid and Flex grid */


/* .flex-content .flexcontentleft .cdk-table .cdk-column-select,
.flexcontentleft .cdk-table .main-column-remove,
.flex-content .cdk-table .flex-column-remove:not(.main-column-remove),
.flex-content .items-per-page {
  display: none;
} */

.flex-content .cdk-table .main-column-remove:not(.flex-column-remove) {
    display: table-cell;
}

.flex-content .items-per-page {
    display: none;
}


/* Modal Dailog Styles */

.rt-dailog-close {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
}

.modal-dialog-wrapper .mat-mdc-dialog-container,.maximize-modal-dialog-wrapper .mat-mdc-dialog-container{
    padding: 0 !important;
    overflow: hidden;
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
    max-height: 500px;
    margin-right: 5px;
    color: #000000de !important;
}

.mdc-dialog__title::before {
    height: 20px !important
}

.mdc-dialog__title {
    padding: 0 !important;
}

.model-dialog-height {
    max-height: 400px;
}

.maximize-modal-dialog-wrapper,
.horizantal-modal-dialog-wrapper .maximize-modal-dialog-wrapper {
    min-width: 90vw !important;
}

.maximize-modal-dialog-wrapper .mat-mdc-dialog-content,
.vertical-modal-dialog-wrapper .maximize-modal-dialog-wrapper .mat-mdc-dialog-content {
    max-height: 75vh !important;
    min-height: 75vh;
}

.modal-dialog-wrapper mat-tab-group .mat-mdc-tab-body-content {
    overflow: hidden;
}

.treeview-model-container .mat-mdc-dialog-content,
.treeview-model-container .mat-mdc-dialog-container {
    overflow: inherit;
    padding: 0;
}

.modal-actions {
    padding: 12px 0 15px 0;
}

.rt-line+.rt-line {
    border-top: 1px solid #ccc;
}

.mat-mdc-menu-item.rt-line-height-24 {
    line-height: 24px !important;
}

#goto-top {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 5px;
    z-index: 99;
    border: none;
    outline: 0;
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 8px !important;
}

.leaflet-container {
    position: relative;
    font-family: "Roboto", sans-serif !important;
}

.list-wrapper .list-item {
    padding: 10px;
    border-right: 4px solid transparent;
}

@keyframes ring {

    0%,
    50% {
        transform: rotate(0);
    }

    1%,
    10%,
    20%,
    30%,
    40% {
        transform: rotate(10deg);
    }

    5%,
    15%,
    25%,
    35%,
    45% {
        transform: rotate(-10deg);
    }
}

.notification-bell-icon {
    animation: ring 2s 0.7s ease-in-out infinite !important;
}


/* Custom file input */

.custom-file-wrapper {
    overflow: hidden !important;
}

.custom-file-wrapper input[type="file"] {
    display: none;
}

.mat-tooltip {
    margin: 7px 14px !important;
    font-size: 13px !important;
    word-break: break-all;
}

.legend-wrapper .legend-box {
    width: 10px;
    height: 10px;
    display: inline-block;
}


/* Vertical Tab Group */

.vertical-tab-group {
    flex-direction: row !important;
}

.vertical-tab-group .mat-mdc-tab-header {
    border-bottom-width: 0;
}

.vertical-tab-group .mat-mdc-tab-header-pagination,
.vertical-tab-group .mdc-tab-indicator {
    display: none !important;
}

.vertical-tab-group>mat-tab-header>.mat-mdc-tab-label-container>.mat-mdc-tab-list>.mat-mdc-tab-labels {
    flex-direction: column;
}

.vertical-tab-group>mat-tab-header>.mat-mdc-tab-label-container>.mat-mdc-tab-list>.mat-mdc-tab-labels>.mat-mdc-tab {
    height: auto;
    padding: 10px 5px;
    width: 35px;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
}

.vertical-tab-group .mat-mdc-tab-body-wrapper {
    flex: 1 1 auto;
}

.vertical-tab-group .mat-mdc-tab-label-container {
    border-right: 1px solid #f2f2f2 !important;
}

.single-marker-wrapper path {
    fill: inherit !important;
}

.rt-display-initial {
    display: initial;
}

// mat-date-picker and ngx-date-picker css properties are overriding
.date-picker-calender-wrapper .mat-calendar-body-cell {
    position: absolute !important;
    height: 100% !important;
}

// Added page break css for cloned report element while generating the pdf
[id*="report-cloned-pdf-"] .page-break-all {
    page-break-after: always;
    margin: 0 !important;
    border: 1px solid transparent;
}

[id*="report-cloned-pdf-"] .page-break-all:last-child {
    page-break-after: avoid !important;
}

//hide zoomcontrols while generating the pdf
[id*="report-cloned-pdf-"] .zoom-controls,
[id*="report-cloned-pdf-"] .signature-icon {
    display: none;
}

/* container shapes clip-path css */

.clip-left-point {
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
}

.clip-right-point {
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.clip-left-arrow {
    clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
}

.clip-right-arrow {
    clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
}

.clip-left-chevron {
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}

.clip-right-chevron {
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.clip-triangle {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.clip-pentagon {
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.clip-star {
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.clip-hexagon {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.right-breadcrumb-arrow {
    position: relative;
}

.right-breadcrumb-arrow::after,
.right-breadcrumb-arrow::before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: var(--arrow-size) solid transparent;
    border-bottom: var(--arrow-size) solid transparent;
    position: absolute;
    top: 0%;
    left: 100%;
    z-index: 3;
}

.right-breadcrumb-arrow::before {
    border-left: var(--arrow-size) solid var(--arrow-border-color);
    margin-left: 1px;
}

.right-breadcrumb-arrow::after {
    border-left: var(--arrow-size) solid var(--arrow-color);
}

.custom-error-tooltip {
    white-space: pre-line;
  }

/* container shapes clip-path css */