@font-face {
    font-family: "custom-icons";
    src: url("./custom-icons.eot?7068a964b69cc1b253af3b8b0014441e?#iefix") format("embedded-opentype"),
url("./custom-icons.woff2?7068a964b69cc1b253af3b8b0014441e") format("woff2"),
url("./custom-icons.woff?7068a964b69cc1b253af3b8b0014441e") format("woff"),
url("./custom-icons.ttf?7068a964b69cc1b253af3b8b0014441e") format("truetype"),
url("./custom-icons.svg?7068a964b69cc1b253af3b8b0014441e#custom-icons") format("svg");
}

i[class^="svg-icons-"]:before, i[class*=" svg-icons-"]:before {
    font-family: custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.svg-icons-3d-box:before {
    content: "\f101";
}
.svg-icons-a-letter-down:before {
    content: "\f102";
}
.svg-icons-a-letter-up:before {
    content: "\f103";
}
.svg-icons-aa-letter:before {
    content: "\f104";
}
.svg-icons-activity:before {
    content: "\f105";
}
.svg-icons-activityug:before {
    content: "\f106";
}
.svg-icons-all-locations:before {
    content: "\f107";
}
.svg-icons-allocation-status:before {
    content: "\f108";
}
.svg-icons-analysis-mode-icon:before {
    content: "\f109";
}
.svg-icons-animation-marker:before {
    content: "\f10a";
}
.svg-icons-app-wifi-settings:before {
    content: "\f10b";
}
.svg-icons-asset-management:before {
    content: "\f10c";
}
.svg-icons-asset-management1:before {
    content: "\f10d";
}
.svg-icons-asset-placeholder:before {
    content: "\f10e";
}
.svg-icons-asset-register:before {
    content: "\f10f";
}
.svg-icons-barcode:before {
    content: "\f110";
}
.svg-icons-biometric-config:before {
    content: "\f111";
}
.svg-icons-blasted-areas:before {
    content: "\f112";
}
.svg-icons-blasting:before {
    content: "\f113";
}
.svg-icons-blownout-hole:before {
    content: "\f114";
}
.svg-icons-boom:before {
    content: "\f115";
}
.svg-icons-bulb:before {
    content: "\f116";
}
.svg-icons-bulk-trips:before {
    content: "\f117";
}
.svg-icons-bulletflow:before {
    content: "\f118";
}
.svg-icons-button:before {
    content: "\f119";
}
.svg-icons-capslock:before {
    content: "\f11a";
}
.svg-icons-card:before {
    content: "\f11b";
}
.svg-icons-charvaka-area:before {
    content: "\f11c";
}
.svg-icons-charvaka-arrow:before {
    content: "\f11d";
}
.svg-icons-charvaka-circle:before {
    content: "\f11e";
}
.svg-icons-charvaka-crop:before {
    content: "\f11f";
}
.svg-icons-charvaka-db:before {
    content: "\f120";
}
.svg-icons-charvaka-diamond:before {
    content: "\f121";
}
.svg-icons-charvaka-end-event:before {
    content: "\f122";
}
.svg-icons-charvaka-hand:before {
    content: "\f123";
}
.svg-icons-charvaka-mail-task:before {
    content: "\f124";
}
.svg-icons-charvaka-process:before {
    content: "\f125";
}
.svg-icons-charvaka-script-task:before {
    content: "\f126";
}
.svg-icons-charvaka-script:before {
    content: "\f127";
}
.svg-icons-charvaka-service-task:before {
    content: "\f128";
}
.svg-icons-charvaka-space:before {
    content: "\f129";
}
.svg-icons-charvaka-square:before {
    content: "\f12a";
}
.svg-icons-charvaka-start-circle:before {
    content: "\f12b";
}
.svg-icons-charvaka-sub-process:before {
    content: "\f12c";
}
.svg-icons-charvaka-user-task:before {
    content: "\f12d";
}
.svg-icons-collapsed-hole:before {
    content: "\f12e";
}
.svg-icons-colorpicker:before {
    content: "\f12f";
}
.svg-icons-common-layer:before {
    content: "\f130";
}
.svg-icons-configurations:before {
    content: "\f131";
}
.svg-icons-consl:before {
    content: "\f132";
}
.svg-icons-conveyor:before {
    content: "\f133";
}
.svg-icons-cost-layer:before {
    content: "\f134";
}
.svg-icons-crusher:before {
    content: "\f135";
}
.svg-icons-crushing-screening:before {
    content: "\f136";
}
.svg-icons-current-status:before {
    content: "\f137";
}
.svg-icons-custom-pages:before {
    content: "\f138";
}
.svg-icons-d:before {
    content: "\f139";
}
.svg-icons-dashboard-info:before {
    content: "\f13a";
}
.svg-icons-dashboard:before {
    content: "\f13b";
}
.svg-icons-datetime:before {
    content: "\f13c";
}
.svg-icons-default:before {
    content: "\f13d";
}
.svg-icons-dialog:before {
    content: "\f13e";
}
.svg-icons-div:before {
    content: "\f13f";
}
.svg-icons-dollar:before {
    content: "\f140";
}
.svg-icons-downarrow:before {
    content: "\f141";
}
.svg-icons-drillholes:before {
    content: "\f142";
}
.svg-icons-drilling-holes:before {
    content: "\f143";
}
.svg-icons-drilling:before {
    content: "\f144";
}
.svg-icons-droplist:before {
    content: "\f145";
}
.svg-icons-dumpped-location:before {
    content: "\f146";
}
.svg-icons-employee-availability:before {
    content: "\f147";
}
.svg-icons-employee:before {
    content: "\f148";
}
.svg-icons-entrygate-loc:before {
    content: "\f149";
}
.svg-icons-entrygate:before {
    content: "\f14a";
}
.svg-icons-equipment-productivity:before {
    content: "\f14b";
}
.svg-icons-events:before {
    content: "\f14c";
}
.svg-icons-excavation-hauling:before {
    content: "\f14d";
}
.svg-icons-excavator-top-view:before {
    content: "\f14e";
}
.svg-icons-excavator:before {
    content: "\f14f";
}
.svg-icons-female-icon:before {
    content: "\f150";
}
.svg-icons-filter:before {
    content: "\f151";
}
.svg-icons-floating-panel:before {
    content: "\f152";
}
.svg-icons-fuel:before {
    content: "\f153";
}
.svg-icons-gc:before {
    content: "\f154";
}
.svg-icons-gps:before {
    content: "\f155";
}
.svg-icons-gross-weighbridge:before {
    content: "\f156";
}
.svg-icons-haul-cycle-analysis:before {
    content: "\f157";
}
.svg-icons-health-and-safety:before {
    content: "\f158";
}
.svg-icons-heatmap:before {
    content: "\f159";
}
.svg-icons-hf-according:before {
    content: "\f15a";
}
.svg-icons-hf-align-bottom:before {
    content: "\f15b";
}
.svg-icons-hf-align-middle:before {
    content: "\f15c";
}
.svg-icons-hf-align-top:before {
    content: "\f15d";
}
.svg-icons-hf-area-chart:before {
    content: "\f15e";
}
.svg-icons-hf-asset:before {
    content: "\f15f";
}
.svg-icons-hf-bar-chart:before {
    content: "\f160";
}
.svg-icons-hf-big-bar-chart:before {
    content: "\f161";
}
.svg-icons-hf-border-arrows:before {
    content: "\f162";
}
.svg-icons-hf-border-color:before {
    content: "\f163";
}
.svg-icons-hf-border-pattern:before {
    content: "\f164";
}
.svg-icons-hf-border-thickness:before {
    content: "\f165";
}
.svg-icons-hf-border-visibility:before {
    content: "\f166";
}
.svg-icons-hf-box-icon:before {
    content: "\f167";
}
.svg-icons-hf-button01:before {
    content: "\f168";
}
.svg-icons-hf-button02:before {
    content: "\f169";
}
.svg-icons-hf-card:before {
    content: "\f16a";
}
.svg-icons-hf-center:before {
    content: "\f16b";
}
.svg-icons-hf-check-box:before {
    content: "\f16c";
}
.svg-icons-hf-chips:before {
    content: "\f16d";
}
.svg-icons-hf-corner-radius:before {
    content: "\f16e";
}
.svg-icons-hf-corner-visibility:before {
    content: "\f16f";
}
.svg-icons-hf-date-picker:before {
    content: "\f170";
}
.svg-icons-hf-donut-chart:before {
    content: "\f171";
}
.svg-icons-hf-drop-down:before {
    content: "\f172";
}
.svg-icons-hf-fill-color:before {
    content: "\f173";
}
.svg-icons-hf-fill-image:before {
    content: "\f174";
}
.svg-icons-hf-font-color:before {
    content: "\f175";
}
.svg-icons-hf-group:before {
    content: "\f176";
}
.svg-icons-hf-label:before {
    content: "\f177";
}
.svg-icons-hf-left:before {
    content: "\f178";
}
.svg-icons-hf-line-chart:before {
    content: "\f179";
}
.svg-icons-hf-menu:before {
    content: "\f17a";
}
.svg-icons-hf-middle:before {
    content: "\f17b";
}
.svg-icons-hf-module:before {
    content: "\f17c";
}
.svg-icons-hf-multiselect:before {
    content: "\f17d";
}
.svg-icons-hf-opacity:before {
    content: "\f17e";
}
.svg-icons-hf-pie-chart:before {
    content: "\f17f";
}
.svg-icons-hf-preview:before {
    content: "\f180";
}
.svg-icons-hf-production:before {
    content: "\f181";
}
.svg-icons-hf-progress-bar:before {
    content: "\f182";
}
.svg-icons-hf-radio-button:before {
    content: "\f183";
}
.svg-icons-hf-right:before {
    content: "\f184";
}
.svg-icons-hf-slider:before {
    content: "\f185";
}
.svg-icons-hf-stepper:before {
    content: "\f186";
}
.svg-icons-hf-strikethrough:before {
    content: "\f187";
}
.svg-icons-hf-table:before {
    content: "\f188";
}
.svg-icons-hf-textarea:before {
    content: "\f189";
}
.svg-icons-hf-textfield:before {
    content: "\f18a";
}
.svg-icons-hf-time-picker:before {
    content: "\f18b";
}
.svg-icons-hf-top:before {
    content: "\f18c";
}
.svg-icons-hf-tree:before {
    content: "\f18d";
}
.svg-icons-hf-typography:before {
    content: "\f18e";
}
.svg-icons-hf-underline:before {
    content: "\f18f";
}
.svg-icons-hf-ungroup:before {
    content: "\f190";
}
.svg-icons-hori-center:before {
    content: "\f191";
}
.svg-icons-hori-flex-end:before {
    content: "\f192";
}
.svg-icons-hori-flex-start:before {
    content: "\f193";
}
.svg-icons-hori-space-around:before {
    content: "\f194";
}
.svg-icons-hori-space-between:before {
    content: "\f195";
}
.svg-icons-hori-space-evenly:before {
    content: "\f196";
}
.svg-icons-horizontal:before {
    content: "\f197";
}
.svg-icons-hybrid-flow:before {
    content: "\f198";
}
.svg-icons-hyperlink:before {
    content: "\f199";
}
.svg-icons-icon:before {
    content: "\f19a";
}
.svg-icons-incident-management:before {
    content: "\f19b";
}
.svg-icons-inprogress-org:before {
    content: "\f19c";
}
.svg-icons-inventory-stock:before {
    content: "\f19d";
}
.svg-icons-inventory:before {
    content: "\f19e";
}
.svg-icons-key:before {
    content: "\f19f";
}
.svg-icons-keynew:before {
    content: "\f1a0";
}
.svg-icons-launchpad:before {
    content: "\f1a1";
}
.svg-icons-lighting:before {
    content: "\f1a2";
}
.svg-icons-link:before {
    content: "\f1a3";
}
.svg-icons-loading-area-check:before {
    content: "\f1a4";
}
.svg-icons-loading-moving:before {
    content: "\f1a5";
}
.svg-icons-manage-event:before {
    content: "\f1a6";
}
.svg-icons-manually-resolve:before {
    content: "\f1a7";
}
.svg-icons-material-stock-layer:before {
    content: "\f1a8";
}
.svg-icons-maximize:before {
    content: "\f1a9";
}
.svg-icons-mine-entry:before {
    content: "\f1aa";
}
.svg-icons-mine-exit:before {
    content: "\f1ab";
}
.svg-icons-minimize:before {
    content: "\f1ac";
}
.svg-icons-module-settings:before {
    content: "\f1ad";
}
.svg-icons-multi-mine-db:before {
    content: "\f1ae";
}
.svg-icons-network-coverage-layer:before {
    content: "\f1af";
}
.svg-icons-op:before {
    content: "\f1b0";
}
.svg-icons-optimization:before {
    content: "\f1b1";
}
.svg-icons-output-view:before {
    content: "\f1b2";
}
.svg-icons-output:before {
    content: "\f1b3";
}
.svg-icons-partial-blast-hole:before {
    content: "\f1b4";
}
.svg-icons-payload:before {
    content: "\f1b5";
}
.svg-icons-personnel:before {
    content: "\f1b6";
}
.svg-icons-pit-loc:before {
    content: "\f1b7";
}
.svg-icons-pit:before {
    content: "\f1b8";
}
.svg-icons-plant-feed:before {
    content: "\f1b9";
}
.svg-icons-play-pause-black:before {
    content: "\f1ba";
}
.svg-icons-play-pause-transparent:before {
    content: "\f1bb";
}
.svg-icons-play-pause-white:before {
    content: "\f1bc";
}
.svg-icons-play-pause:before {
    content: "\f1bd";
}
.svg-icons-polygon:before {
    content: "\f1be";
}
.svg-icons-popupicon:before {
    content: "\f1bf";
}
.svg-icons-post-blast:before {
    content: "\f1c0";
}
.svg-icons-postcheck:before {
    content: "\f1c1";
}
.svg-icons-pre-blast:before {
    content: "\f1c2";
}
.svg-icons-precheck:before {
    content: "\f1c3";
}
.svg-icons-prestart:before {
    content: "\f1c4";
}
.svg-icons-qrcode:before {
    content: "\f1c5";
}
.svg-icons-report:before {
    content: "\f1c6";
}
.svg-icons-reports:before {
    content: "\f1c7";
}
.svg-icons-resource:before {
    content: "\f1c8";
}
.svg-icons-resourceallocation:before {
    content: "\f1c9";
}
.svg-icons-rfid:before {
    content: "\f1ca";
}
.svg-icons-schedules:before {
    content: "\f1cb";
}
.svg-icons-scrollpanel:before {
    content: "\f1cc";
}
.svg-icons-sd:before {
    content: "\f1cd";
}
.svg-icons-shift-closed:before {
    content: "\f1ce";
}
.svg-icons-static-accordion:before {
    content: "\f1cf";
}
.svg-icons-static-tab:before {
    content: "\f1d0";
}
.svg-icons-stethoscope:before {
    content: "\f1d1";
}
.svg-icons-stock-in:before {
    content: "\f1d2";
}
.svg-icons-stock-out:before {
    content: "\f1d3";
}
.svg-icons-stockpile:before {
    content: "\f1d4";
}
.svg-icons-stoped:before {
    content: "\f1d5";
}
.svg-icons-tag:before {
    content: "\f1d6";
}
.svg-icons-tare-weighbridge:before {
    content: "\f1d7";
}
.svg-icons-targets-actuals:before {
    content: "\f1d8";
}
.svg-icons-temperature:before {
    content: "\f1d9";
}
.svg-icons-time:before {
    content: "\f1da";
}
.svg-icons-timeline:before {
    content: "\f1db";
}
.svg-icons-tipper:before {
    content: "\f1dc";
}
.svg-icons-trip-edited:before {
    content: "\f1dd";
}
.svg-icons-trip-jump:before {
    content: "\f1de";
}
.svg-icons-tripcount:before {
    content: "\f1df";
}
.svg-icons-trips:before {
    content: "\f1e0";
}
.svg-icons-truck-marker:before {
    content: "\f1e1";
}
.svg-icons-truck:before {
    content: "\f1e2";
}
.svg-icons-vert-center:before {
    content: "\f1e3";
}
.svg-icons-vert-flex-end:before {
    content: "\f1e4";
}
.svg-icons-vert-flex-start:before {
    content: "\f1e5";
}
.svg-icons-vert-space-around:before {
    content: "\f1e6";
}
.svg-icons-vert-space-between:before {
    content: "\f1e7";
}
.svg-icons-vert-space-evenly:before {
    content: "\f1e8";
}
.svg-icons-vertical:before {
    content: "\f1e9";
}
.svg-icons-weighbridge:before {
    content: "\f1ea";
}
.svg-icons-weighbridge1:before {
    content: "\f1eb";
}
.svg-icons-whatsapp:before {
    content: "\f1ec";
}
.svg-icons-wighbridge-loc:before {
    content: "\f1ed";
}
